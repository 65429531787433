import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { CONSTANTS,ENCYPT_KEY} from '../../util/Constants';
import {setHeaderUtil, saveCurrentStateUtil, setNotification, setModalPopupUtil, setLoadingUtil, isComplexTab,logout } from '../home/Utils';
import { connect } from 'react-redux';
import { setLoading, logOut } from '../../actions';
//import { useHistory } from 'react-router-dom';
import { store } from '../../index';
import { encrypt } from "../../util/Util";
import { CHANGE_PIN_POLICY_URL,CHANGE_PIN_URL, CHANNEL, AUTH_KEY ,ENCYPT_KEY_AES} from '../../util/Constants';
//import {}
import { AjaxUtil } from '@6d-ui/ajax';
import {Redirect} from 'react-router-dom';
const ajaxUtil = new AjaxUtil({
  'responseCode': {
    'success': 200,
    'unAuth': 401,
    'resultSuccess': '0'
  },
  'messages': {
    'success': "Success Message",
    'failure': "Failure Message"
  },
  'authKey': AUTH_KEY,
  'channel': CHANNEL,
  'logout' : logout,
  'encrptKey': ENCYPT_KEY_AES,
  'encryptAES':true
});

function ForcePin(props) {

  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passShow, setPassShow] = useState('');
  const [confPassShow, setConfPassShow] = useState('');
  const [newPassShow, setNewPassShow] = useState(''); 
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isShow, setIsShow] = useState({password:false,newPass:false,confPass:false});
  //const history = useHistory();

console.log("props----->F",props);
  const height = useWindowHeight();
  const handlePassworChange = (name, val) => {
    if (name === "password") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = password;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < password.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setPassShow(str);
        setPassword(value);
      } else {
        setPassShow('');
        setPassword('');
      }
    }
    if (name === "confPass") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = confirmPassword;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < confirmPassword.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setConfPassShow(str);
        setConfirmPassword(value);
      } else {
        setConfPassShow('');
        setConfirmPassword('');
      }
    }
    if (name === "newPass") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = newPassword;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < newPassword.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setNewPassShow(str);
        setNewPassword(value);
      } else {
        setNewPassShow('');
        setNewPassword('');
      }
    }
  }
  function setShow(name){
    const isShowTmp={...isShow};
    if(name==="password"){
      isShowTmp.password=!isShowTmp.password;
    }
    if(name==="newPass"){
      isShowTmp.newPass=!isShowTmp.newPass;
    }
    if(name==="confPass"){
      isShowTmp.confPass=!isShowTmp.confPass;
    }
    setIsShow(isShowTmp);
  }
  const handleLoginSubmit = (e) => {
      var ft= new RegExp("^[0-9]{4}$");
     
    e.preventDefault();
    console.log("---->",password)
    console.log("---->",ft.test(password))
    if (!password) {
      setError("Please enter your current pin !");
      return false;
    }
    if(!ft.test(password))
    {
        setError("Old Pin should only contain 4 digits");
        return false;
    }
    if(!ft.test(newPassword))
    {
        setError("New Pin should only contain 4 digits");
        return false;
    }
  
    if (!newPassword) {
      setError("Please enter your new pin !");
      return false;
    }
    if (!confirmPassword) {
      setError("Please confirm your new pin !");
      return false;
    }
    if (confirmPassword != newPassword) {
      setError("New Password and Confirm pin must be same !");
      return false;
    }
    const createCheck=(response,hasError)=>
    {
        if(hasError)
        {
          setError(response.message);
          return 
        }
        else
        {
          store.dispatch(logOut("Password succesfully changed"));
        }
      // else
      // {
      //   return true;

      // }
      //const respMsg = response.responseMsg || "Please Try Again";
      //props.setLoginMessage("");
    }
    let userId;
    if (props.login)
      userId = props.login.userDetails && props.login.userDetails.userId
    const req = {
      //userId: userId,
      //password: encrypt(password),
      // newPassword: encrypt(this.state.newPwd),
      //newPassword: encrypt(newPassword),
      // confirmPassword: encrypt(this.state.confirmPwd)
      //confirmPassword: encrypt(confirmPassword)
      
        //  pin: md5(values.oldPin),
        senderMsisdn:props.login.userDetails.msisdn,
        pin: encrypt(password),
        newPin: encrypt(newPassword),
        confirmPin:encrypt(confirmPassword)
  
    }
      
    const getPolicyRequest = {
        newPin: encrypt(newPassword),
        msisdn:props.login.userDetails.msisdn
      };
    
    setError("");

    ajaxUtil.sendRequest(CHANGE_PIN_POLICY_URL, getPolicyRequest,(response,hasError)=>{
      if(hasError)
      {
        if(response)
        {
          setError(response.message);
        }
        
      }
      else{

        setError("");
        ajaxUtil.sendRequest(CHANGE_PIN_URL, req,createCheck,null, { isShowSuccess: true, isShowFailure: false })
      }

    },null, { isShowSuccess: true, isShowFailure: false })

   
    
  };
  const formContainerRef = useRef(null);
  let formHeight = 414;
//   useEffect(() => {
//     formHeight = formContainerRef.current.offsetHeight;
//   }, []);
  const getErrorMsg = () => {
    if (error || props.message) {
      return (
        <div className="errorMsg_login" style={{color:'#ed1c24'}}>
          {error || props.message}
        </div>
      );
    }
  }
  return (
    props.login && !props.login.isLoggedIn?<Redirect to="/login" />:
    <Container fluid>
      <Row>
        <Col xs={6} lg={8} style={{ height: `${height}px`, background:'linear-gradient(40deg, #F9CF01, #ED7818, #007AC3)' }} className="bg-secondary login-container"></Col>
        <Col xs={6} lg={4} className="bg-white">
          <div>
            <div>
              <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px`, marginLeft: '100px' }}>
                <img src={`${process.env.PUBLIC_URL}/images/logo/MM_logo.png`} alt="6d Technologies" style={{ height: '100px', maxWidth: '180px' }} />
              </div>

              {/* login form starts here */}
              <div id="sampleChar" style={{ display: 'none' }}>&#183;</div>
              <div className="login-form-container bg-white" ref={formContainerRef} style={{ marginLeft: '-232.5px' }}>
              <div className="login-form-header">Change Pin</div>
                <form onSubmit={handleLoginSubmit} className="login-form">
                
                  {getErrorMsg()}
                  <div className="pt-1" style={{ color: '#', cursor: 'pointer' }}>
                    <InputGroup>
                      <Input  maxLength="4" onChange={(event) => handlePassworChange("password", event.target.value)}
                        value={isShow.password?password:passShow}  onPaste={(e)=>e.preventDefault()}
                      />
                      <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={()=>setShow("password")}></i></InputGroupAddon>
                      <span className="floating-label">Old Pin</span>
                    </InputGroup>
                  </div>
                  <div className="pt-1" style={{ color: '#495057', cursor: 'pointer' }}>
                    <InputGroup>
                      <Input maxLength="4" onChange={(event) => handlePassworChange("newPass",event.target.value)}
                        value={isShow.newPass?newPassword:newPassShow}  onPaste={(e)=>e.preventDefault()}
                      />
                      <InputGroupAddon  addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={()=>setShow("newPass")}></i></InputGroupAddon>
                      <span className="floating-label">New Pin</span>
                    </InputGroup>
                  </div>
                  <div className="pt-1" style={{ color: '#495057', cursor: 'pointer' }}>
                    <InputGroup>
                      <Input maxLength="4" onChange={(event) => handlePassworChange("confPass",event.target.value)}
                        value={isShow.confPass?confirmPassword:confPassShow}  onPaste={(e)=>e.preventDefault()}
                      />
                      <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={()=>setShow("confPass")}></i></InputGroupAddon>
                      <span className="floating-label">Confirm new Pin</span>
                    </InputGroup>
                  </div>

                  {
                    props.isLoading
                      ? <Button className="w-100 login-button primary-background" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }} disabled>
                        <i className="fa fa-spinner fa-spin"></i><span> authenticating...</span>
                      </Button>
                      : <Button className="w-100 login-button c-pointer primary-background" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }}>Submit</Button>}
                 
                 <div className="d-flex justify-content-end">
                                            <Button onClick={()=>store.dispatch(logOut())} style={{backgroundColor:"#f1f2f300",borderColor:"#ffffff",color:"black"}}><i class="fa fa-arrow-left" aria-hidden="true"></i>    Back to Login</Button> 
                                        </div>
                </form>

              </div>
             
              <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px` }}>
                <span>Tashicell ©2020. All Rights Reserved</span>
              </div>

            </div>
          </div>
        </Col>
      </Row>

    </Container>
  )
}


function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}

function mapStateToProps({ login }) {
  return { login };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (...obj) => dispatch(setLoading(...obj)),
    setLoginMessage: (msg) => dispatch(logOut())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ForcePin);
