// import React, { useRef } from 'react';
// import QRCode from 'qrcode.react';
// import ReactToPrint from 'react-to-print';

// export class QRCodeContents extends React.Component {

//     render() {
//         const { 
//             qrCode, 
//             renderAs='canvas', 
//             size=128, 
//             bgColor="#FFFFFF", 
//             fgColor="#000000", 
//             includeMargin=false, 
//             name,
//             printable = false
//         } = this.props;

//         return (
//             <div style={{
//                 textAlign: 'center', 
//                 minHeight: '250px', 
//                 fontSize: '16px', 
//                 marginBottom: '14px',
//                 width: '100%'
//             }}>
//                 <div style={{ width: '200px', margin: '0 auto', padding: '10px' }}>
//                     {/* <div style={{ margin: '40px 0px' }}>
//                         <img src={`${process.env.PUBLIC_URL}/images/logo/6d-logo.png`} />
//                     </div> */}
//                     <div style={{ margin: '10px 0px' }}>
//                         <span style={{ fontWeight: 'bolder' }}>{name}</span>
//                         <div style={{ background: '#0185e1' }}>
//                             <hr style={{ margin: '5px' }}/>
//                         </div>
//                         <span>Scan QR Code</span>
//                     </div>
//                     <QRCode 
//                         style={{ height: "150px", width: "150px" }}
//                         value={qrCode} 
//                         renderAs={renderAs}
//                         size={size}
//                         bgColor={bgColor}
//                         fgColor={fgColor}
//                         includeMargin={includeMargin}
//                     />
//                 </div>
//             </div>
//         );
//     }

// };


// export default (props) => {

//     const componentRef = useRef();
//     const { 
//         qrCode, 
//         renderAs='canvas', 
//         size=128, 
//         bgColor="#FFFFFF", 
//         fgColor="#000000", 
//         includeMargin=false, 
//         name,
//         showPrint=false
//     } = props;

//     return (
//         <React.Fragment>
//             <QRCodeContents
//                 ref={componentRef}
//                 qrCode={qrCode}
//                 renderAs={renderAs}
//                 size={size}
//                 bgColor={bgColor}
//                 fgColor={fgColor}
//                 includeMargin={includeMargin}
//                 name={name}
//                 printable={showPrint}
//             />    
//             {
//                 showPrint ? 
//                 <div style={{ width: '100%', background: '#0185e1', textAlign: 'center', color: 'white', padding: '15px' }}>
//                 <ReactToPrint 
//                     trigger={() => (
//                         <span style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer' }}>
//                             <i class="fa fa-print" aria-hidden="true"></i> Print
//                         </span>  
//                     )}
//                     content={() => componentRef.current}
//                 >
//                 </ReactToPrint>
//                 </div> : null
//             }
//         </React.Fragment>
//     )
// };
import React, { useRef } from 'react';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';

export class QRCodeContents extends React.Component {

    render() {
        const { 
            qrCode, 
            renderAs='canvas', 
            size=128, 
            bgColor="#FFFFFF", 
            fgColor="#000000", 
            includeMargin=false, 
            name,
            msisdn,
            printable = false
        } = this.props;

        return (
            <div style={{
                textAlign: 'center', 
                minHeight: '250px', 
                fontSize: '16px', 
                marginBottom: '14px',
                width: '100%',
            
            }}>
                <div style={{ width: '200px', margin: '0 auto', padding: '10px' }}>
                    {/* <div style={{ margin: '40px 0px' }}>
                        <img src={`${process.env.PUBLIC_URL}/images/logo/6d-logo.png`} />
                    </div> */}
                    <div><img src={`${process.env.PUBLIC_URL}/images/logo/QRCODE.png`}></img></div>
                    <div style={{ margin: '10px 0px' }}>
                        {/* <span style={{fontWeight:900,color:'rgba(239, 92, 48, 0.99)',fontSize: '25px'}}>{name}</span> */}
                        <span style={{fontWeight:900,color:'#2263da',fontSize: '25px'}}>{name}</span>
                        <div style={{ background: '#0185e1' }}>
                            <hr style={{ margin: '5px' }}/>
                        </div>
                        {/* <span style={{fontWeight:600,color:'rgba(239, 92, 48, 0.99)'}}>Scan QR Code</span> */}
                        <span style={{fontWeight:600,color:'rgba(81, 135, 234, 0.94)'}}>Scan QR Code</span>
                    </div>
                    <QRCode 
                        style={{ height: "160px", width: "160px" }}
                        value={qrCode} 
                        renderAs={renderAs}
                        size={size}
                        bgColor={bgColor}
                        fgColor={fgColor}
                        includeMargin={includeMargin}
                    />
                    <div style={{fontWeight:900,color:'#000000',fontSize: '20px',paddingTop:'5px'}}>{msisdn}</div>
                </div>
            </div>
        );
    }

};


export default (props) => {

    const componentRef = useRef();
    const { 
        qrCode, 
        renderAs='canvas', 
        size=128, 
        bgColor="#FFFFFF", 
        fgColor="#000000", 
        includeMargin=false, 
        msisdn,
        showPrint=false,
        name
    } = props;
    
    return (
        <React.Fragment>
            <QRCodeContents
                ref={componentRef}
                qrCode={qrCode}
                renderAs={renderAs}
                size={size}
                bgColor={bgColor}
                fgColor={fgColor}
                includeMargin={includeMargin}
                name={name}
                msisdn={msisdn}
                printable={showPrint}
            />    
            {
                showPrint ? 
               // <div style={{ width: '100%', background: 'rgba(236, 116, 45, 0.99)', textAlign: 'center', color: 'white', padding: '15px'}}>
                <div style={{ width: '100%', background: '#0185e1', textAlign: 'center', color: 'white', padding: '15px' }}>
                <ReactToPrint 
                    trigger={() => (
                        <span style={{ fontSize: '14px', fontWeight: 'bold', cursor: 'pointer'}}>
                            <i class="fa fa-print" aria-hidden="true"></i> Print
                        </span>  
                    )}
                    content={() => componentRef.current}
                >
                </ReactToPrint>
                </div> : null
            }
        </React.Fragment>
    )
};