import React, { useState, Fragment, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { AjaxUtil } from '@6d-ui/ajax';
import { Redirect } from 'react-router-dom';
import { FormElements } from './utils/FormElements';
import {dateToStringFormatter, encrypt} from '../../util/Util'
import { CHANNEL, AUTH_KEY,ENCYPT_KEY ,ENCYPT_KEY_AES} from "../../util/Constants";

import {CONSTANTS} from '../../util/Constants';
import { FieldItem, FIELD_TYPES, useFieldItem } from '@6d-ui/fields';
import {NEW_PASSWORD_SUCCESS} from '../../util/ActionConstants';
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardHeader
} from 'reactstrap';
import moment from 'moment';
import { CustomButton, BUTTON_TYPE, BUTTON_STYLE, BUTTON_SIZE, BUTTON_ALIGN } from '@6d-ui/buttons';
import {Link} from 'react-router-dom';
import {NO_OF_YEARS} from '../../../src/util/Constants';

const ajaxUtil = new AjaxUtil({
    'responseCode' : {
        'success' : 200,
        'unAuth' : 401,
        'resultSuccess' : '0'
      },
      
      'messages' : {
        'success' : "Success...!",
        'failure' : "Failure...!"
       
      },

      'authKey': AUTH_KEY,
      'channel': CHANNEL,
      'encrptKey': ENCYPT_KEY_AES,
      'encryptAES':true
});

function ForgetPwd(props) {
    const [redirectToLogin, setRedirectToLogin] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [Open, setOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [values, fields, handleChange, { validateValues }] = useFieldItem(FormElements, {});
    
    const [disable, setDisable] = useState(false);

    const formContainerRef = useRef(null);
    const height = useWindowHeight(formContainerRef.current && formContainerRef.current.offsetHeight ? formContainerRef.current.offsetHeight : 0);


    const checkCredentials = (response, hasError) => {

        if(hasError){
            setErrorMsg(response.message)
        }else {
            props.mapDispatchToProps();
            setSubmit(true);
        }
        getErrorMsg(response, hasError);
        
    }

    const createCheck = (response, hasError) => {
        if(hasError){
            setOpen(false);
            setErrorMsg(response.message);
        }
        else {
            setOpen(true);
            setDisable(true);
        }
        getErrorMsg(response, hasError);
        
    }

    const getErrorMsg = (response, hasError) => {
        if (hasError || errorMsg) {
            return (
                             
                <div className={hasError ?'success_login' :'errorMsg_login'} style={{ margin: '10px 14px' }}>
                    {errorMsg}
                </div>
            );
        }else {
            return <div style={{ minHeight: '22px' }}></div>;
        }
    }
    let formHeight = 414;
    useEffect(() => {
        formHeight = formContainerRef.current.offsetHeight;
    }, [formContainerRef.current && formContainerRef.current.offsetHeight ? formContainerRef.current.offsetHeight : 0]);

    const handleUpdate = () => {
        if(Open ){
            const URL = `${CONSTANTS.FORGOT.PASSWORD}/commit?msisdn=${values.mobileNumber}&otp=${encrypt(values.otp)}`;
            let passwordError = false;
        
            validateValues(["otp","password", "confirmedpassword"])
        if (values.confirmedpassword === values.password){
            ajaxUtil.sendRequest(URL, {
                "newPassword": encrypt(values.password),
                "confirmPassword": encrypt(values.confirmedpassword)
            }, checkCredentials, null, {method: 'POST' ,isShowSuccess: true });
    }
        if (values.confirmedpassword != values.password){
            passwordError = true;
            setErrorMsg("password and confirmed password are not matching")
            return (
            <div className={passwordError ?'success_login' :'errorMsg_login'}>
                    {errorMsg}
            </div> )   
        }
    }
        else{
            validateValues(["mobileNumber","date"])
        
        if (values.date != undefined && values.mobileNumber != undefined ){
        setErrorMsg(undefined);
        const URL = `${CONSTANTS.FORGOT.PASSWORD}?msisdn=${values.mobileNumber}&dob=${dateToStringFormatter(values.date)}`;
        ajaxUtil.sendRequest(URL, {}, createCheck, null, {method: 'POST' ,isShowSuccess: true });
        }}}
    return (
        <Fragment>
            {
                redirectToLogin === true && <Redirect to="/login" push />
            }
            {/* <Container className="mw-none" style={{ height: "100%" }}>
                <Row style={{ height: '734px', position: 'absolute', width: '100%' }}>
                    <Col lg="8" md="8" className="login-container">

                    </Col>
                    <Col lg="4" md="4" style={{ background: "#0185E1" }}>
                        <img src={`${process.env.PUBLIC_URL}/images/logo/logo@2x.svg`} alt="6d Technologies" style={{ marginTop: '50px', height: '30px' }} />
                        <div style={{ color: '#ffffff', position: 'absolute', bottom: '0px', padding: '10px 0px', fontWeight: 'lighter', fontSize: '12px' }}>
                            powered by - <b>6d Technologies</b>
                        </div>
                    </Col>

                </Row> */}
                <Container fluid>
                    <Row style={{ height: "100%", width: "100%" }}>
                        <Col xs={6} lg={8} style={{ height: `${height}px`, background:'linear-gradient(40deg, #F9CF01, #ED7818, #007AC3)'}} className="bg-secondary login-container"></Col>
                        {/* style={{ overflowY: 'scroll' }} */}
                        <Col xs={6} lg={4} className="bg-white">
                    {/* <Col lg="6">

                    </Col>
                    <Col lg="6"> */}
                        {/* <div style={{ display: "table", height: "100%" }}> */}
                            {/* <div style={{ display: "table-cell", padding: '150px 0px 0px 0px' }}> */}
                            <div>
                                <div>

                                    <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px`, marginLeft: '100px' }}>
                                        <img src={`${process.env.PUBLIC_URL}/images/logo/MM_logo.png`} alt="6d Technologies" style={{ height: '100px', maxWidth: '180px' }} />
                                    </div>

                                    <div className="login-form-container bg-white" ref={formContainerRef} style={{ marginLeft: '38px' }}>
                                        <div className="login-form-header" style={{ borderBottom: '0', lineHeight: '20px',fontSize: '20px', height: '22px' }}>
                                            Forgot your Password?
                                        </div>

                                {/* <Card style={{ margin: 0, padding: 0, width: '500px' }}> */}
                                    {/* <CardHeader> */}
                                        {/* <span> */}
                                            {/* <b>Forgot your Password?</b> */}
                                        {/* </span> */}
                                    {/* </CardHeader> */}
                                    {/* <CardBody> */}
                                        
                                        {getErrorMsg()}
                                        {/* <div class="clearfix"> */}
                                            
                                        <FieldItem
                                            {...FormElements.mobileNumber}
                                            value={values.mobileNumber}
                                            type={FIELD_TYPES.TEXT}
                                            onChange={(...e) => handleChange('mobileNumber', ...e)}
                                            touched={fields.mobileNumber && fields.mobileNumber.hasError}
                                            error={fields.mobileNumber && fields.mobileNumber.errorMsg}
                                            disabled = { disable }
                                        />

                                        <FieldItem 
                                            {...FormElements.date}
                                            value={values.date}
                                            type={FIELD_TYPES.DATE_PICKER} dateFormat="DD/MM/YYYY"
                                            maxDate = {moment().subtract(NO_OF_YEARS, 'years')}
                                            onChange={(...e) => handleChange('date', ...e)} touched={fields.date && fields.date.hasError}
                                            error={fields.date && fields.date.errorMsg}
                                            disabled = { disable }
                                        />
                                        {Open ? <div><FieldItem 
                                            {...FormElements.otp}
                                            value={values.otp} 
                                            type={FIELD_TYPES.TEXT}
                                            onChange={(...e) => handleChange('otp', ...e)} 
                                            touched={fields.otp && fields.otp.hasError}
                                            error={fields.otp && fields.otp.errorMsg}
                                            inputType="password"
                                        />
                                        <FieldItem 
                                            {...FormElements.password}
                                            value={values.password}
                                            type={FIELD_TYPES.TEXT}
                                            onChange={(...e) => handleChange('password', ...e)} 
                                            touched={fields.password && fields.password.hasError}
                                            error={fields.password && fields.password.errorMsg}
                                            inputType="password"
                                        />
                                        <FieldItem 
                                            {...FormElements.confirmedpassword}
                                            value={values.confirmedpassword}
                                            type={FIELD_TYPES.TEXT}
                                            onChange={(...e) => handleChange('confirmedpassword', ...e)} 
                                            touched={fields.confirmedpassword && fields.confirmedpassword.hasError}
                                            error={fields.confirmedpassword && fields.confirmedpassword.errorMsg}
                                            inputType="password"
                                        />
                                        </div>
                                        : ""}
                                        {submit && values.confirmedpassword != null && values.password != null ? <Redirect to="/login" push /> : ""}
                                    {/* </div> */}
                                        <div className="login-btn" style={{ paddingLeft:'6px', paddingRight: '22px' }}>
                                            <CustomButton onClick = { handleUpdate } style={BUTTON_STYLE.BRICK} type={BUTTON_TYPE.PRIMARY} size={BUTTON_SIZE.MEDIUM_LARGE}
                                            width = {BUTTON_ALIGN.INHERIT}
                                            label="Proceed"  isButtonGroup={true} />
                                           </div>
                                        <div style={{ marginTop: '8px', float: 'right', marginRight: '16px' }}>
                                            {/* <a href="/login">Go back to Login</a> */}
                                            <Link to="/login">Go back to Login</Link>
                                        </div>
                                    </div>
                                    {/* </CardBody> */}
                                {/* </Card> */}
                            </div>
                        </div>
                    </Col>

                </Row>
            </Container>
            </Fragment>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        type: NEW_PASSWORD_SUCCESS
    }
}

function useWindowHeight(offsetHeight) {
    const [height, setHeight] = useState(window.innerHeight);
    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        console.log("offsetHeight",offsetHeight, "height", height);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [offsetHeight]);

    return height;
}


export default connect(()=>{},{mapDispatchToProps})(ForgetPwd);