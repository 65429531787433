import React, { Component } from "react";
import _ from 'lodash';
import { Container, Breadcrumb, BreadcrumbItem, Row, Col } from "reactstrap";
import { SideMenu, Header, Notification, Alert, Loader } from '@6d-ui/ui-components';
import { withRouter } from 'react-router'
import { MENU_DETAILS } from "../../util/Privilages";
import { connect } from "react-redux";
import { Routes } from './sub/Routes'
import { setModalPopup, logOut, addToNotification, removeFromNotification, clearAllNotification } from '../../actions'
import { store } from '../../index';
import ResponsiveContainer from "../util/ResponsiveContainer";
import { GLOBAL_CONSTANTS, CONSTANTS } from '../../util/Constants';
import { Popup, POPUP_ALIGN } from '@6d-ui/popup';
import QRCode from './QRCode';
import { ajaxUtil } from './Utils';
import RouteValidator from "./sub/RouteValidator";

class Home extends Component {

    constructor(props) {
        super(props);

        const HeaderButtons = [
            {
                id: 1,
                type: 1,
                icon: 'fa-bell',
                animatedIcon: 'fa-bell faa-ring animated faa-slow'
            },
            {
                id: 2,
                type: 2,
                icon: 'fa-user-circle-o',
                subIcon: 'fa-user-o',
                subHeader: this.props.login.userDetails.name,

                subItems: [
                    {
                        id: 21,
                        name: 'My Profile',
                        icon: 'fa-user-o'
                    },
                    {
                        id: 25,
                        name: 'QR Code',
                        icon: 'fa-qrcode'
                    },
                    {
                        id: 22,
                        name: 'Change Password',
                        icon: 'fa-key'
                    },

                    {
                        id: 24,
                        name: 'Change Pin',
                        icon: 'fa-key'
                    },
                    {
                        id: 23,
                        name: 'Sign Out',
                        icon: 'fa-sign-out'
                    },
                ]
            }
        ];

        const headerButton = [...HeaderButtons];

        if (!this.props.login.userDetails || !this.props.login.userDetails.qrCode) {
            headerButton.map(val => {
                if (val && val.subItems && val.subItems.length > 0) {
                    _.remove(val.subItems, (o) => o.id === 25);
                }
                return val;
            });
        }
        if (this.props && this.props.login && this.props.login.userDetails && 
            this.props.login.userDetails.alerts != "" && this.props.login.userDetails.alerts != null) {
            this.props.addToNotification({
                id: (+ new Date() + Math.floor(Math.random() * 999999)).toString(36),
                message: this.props.login.userDetails.alerts,
                // message: "Your balance is low please recharge !!",
                downloadable: false,
                type: "Alerts"
            });
        }

        this.state = {
            isSideNavShown: true,
            isLoggedIn: false,
            mode: "0",
            entity: this.props.login.userDetails.entity,
            MENU_DETAILS,
            openQrCode: false,
            bussinessName: '',
            HEADER_BUTTONS: headerButton
        };
        this.toggleSideNav = this.toggleSideNav.bind(this);
        this.route = this.route.bind(this);
    }
    removeAlert(id) {
        this.props.removeFromNotification(id);
    }
    toggleSideNav() {
        this.setState({ isSideNavShown: !this.state.isSideNavShown });
    }
    componentDidMount() {
        let that = this;
        window.scrollTo(0, 0);
        const getrequest = () => {
            return {
                status: 1,
            };
        }
        ajaxUtil.sendRequest(CONSTANTS.TASK.FETCH_DROPDOWN_LIST,
            getrequest(), (response, hasError) => {
                if (!hasError && response) {
                    if (response.length > 0) {
                        var notes = [];
                        response.map(m => {

                            notes.push({
                                id: m.id,
                                message: m.message,
                                url: m.redirectUrl == '/tasks' ? '/tasks' : '',
                                type: "Redirect"
                            })
                        }
                        )

                        this.props.addToNotification(notes);
                    }

                }
            }, this.props.loadingFunction, { method: "GET", isShowSuccess: false, isShowFailure: false });

            //Notfication for Salary Approval added
            ajaxUtil.sendRequest(`${CONSTANTS.SALARY_APROVAL.LIST_URL}approverUserId=${this.props.login.userDetails.userId}&status=0`,
                {}, (response, hasError) => {
                    if (!hasError && response) {
                        if (response.content.length > 0) {
                            var salaryList = [];
                               salaryList=response.content.reduce((opts,resp)=>{
                                opts = [...opts, {
                                     id: resp.id,
                                     message: "Salary Approval request by Corporate"+ resp.corporateId + "",
                                     url: '/salaryApprove',
                                     type: "Redirect"
                                }]
        
                                return opts;
                               },[])
                            this.props.addToNotification(salaryList);
                        }
    
                    }
                }, this.props.loadingFunction, { method: "GET", isShowSuccess: false, isShowFailure: false });
    
    }
    route(id, link) {
        if (this.props.location.pathname !== link) {
            this.props.history.push(link);
        } else {
            this.props.history.replace(`/_refresh`); // this is to rerender the route
            setTimeout(() => {
                this.props.history.replace(link);
            });
        }
    }
    onHeaderItemClick(menuId, SubMenuId) {
        if (menuId === 2 && SubMenuId === 22) {
            this.route(menuId, '/changePswd');
        }
        if (menuId === 2 && SubMenuId === 23) {
            store.dispatch(clearAllNotification());
            store.dispatch(logOut());
        }
        if (menuId === 2 && SubMenuId === 24) {
            this.route(menuId, '/changePin');
        }
        if (menuId === 2 && SubMenuId === 21) {
            if (!(this.state.entity === GLOBAL_CONSTANTS.ENTITY_IDS.OPERATOR)) {
                localStorage.setItem("userId", this.props.login.userDetails.userId);
                localStorage.setItem("showActions", 'f');
                this.route(menuId, `/channels/view`);
            } else {
                this.route(menuId, '/userProfile');
            }
        }
        if (menuId === 2 && SubMenuId === 25) {
            this.toggleQRCodeWindow();
        }
    }

    toggleQRCodeWindow = () => {
        if (this.props.login.userDetails &&
            this.props.login.userDetails.entity == GLOBAL_CONSTANTS.ENTITY_IDS.MERCHANT) {
            // api call to get bussiness name for merchant 
            ajaxUtil.sendRequest(`${CONSTANTS.ACCOUNT_HOLDER.GET_BUSSINESS_DETAILS}/${this.props.login.userDetails.userId}/`, {}, (response, hasError) => {
                if (!hasError && response) {
                    this.setState({ bussinessName: response.companyName })
                }
            }, this.props.loadingFunction, { method: 'GET', isShowFailure: false, isShowSuccess: false })
        }
        this.setState({ openQrCode: !this.state.openQrCode });
    }

    exportResponseHandler = (exportResponse, item, fileName) => {
        if (exportResponse) {
            const { headers } = exportResponse;
            const contentType = headers['content-type'];
            let fileType = 'xls';
            if (item && item.type) {
                fileType = item.type;
            } else {
                if (item.label) {
                    switch (item.label.toLowerCase()) {
                        case 'excel':
                            fileType = 'xls'; break;
                        case 'csv':
                            fileType = 'csv'; break;
                        default: break;
                    }
                }
            }
            if (fileName)
                fileName = fileName.endsWith("-") ? (fileName + Date.now()) : (fileName + "-" + Date.now());
            else
                fileName = Date.now();

            fileName = fileName + "." + fileType;

            this.props.addToNotification({
                id: (+ new Date() + Math.floor(Math.random() * 999999)).toString(36),
                message: fileName,
                url: window.URL.createObjectURL(exportResponse.data),
                downloadable: true
            });
        }
    }

    removeFromExportArray = id => {
        this.props.removeFromNotification(id);
    }

    render() {

        const getBreadCrumb = () => {
            if (this.props.breadcrumb && this.props.breadCrumb.length > 0) {
                return (
                    <Container className="main_breadCrumb_container">
                        <Row>
                            <Col>
                                <Breadcrumb
                                    className="main_breadCrumb">
                                    <BreadcrumbItem>
                                        <i className="fa fa-home" />
                                    </BreadcrumbItem>
                                    <BreadcrumbItem active>Dash Board</BreadcrumbItem>
                                </Breadcrumb>
                            </Col>
                        </Row>
                    </Container>
                );
            }
        };
        const sideNavStyle = {
            marginLeft: this.state.isSideNavShown ? 0 : '-202px'
        };
        
        return (
            <div>
                <div className="home-main-div">
                    <div className="side-nav-menu" style={sideNavStyle}>
                        <SideMenu
                            currentPath={this.props.location.pathname}
                            privilages={this.props.login.userDetails.privilages}
                            route={this.route}
                            menus={this.state.MENU_DETAILS}
                            //logo={`${process.env.PUBLIC_URL}/images/logo/6d-logo.png`}
                            logo={`${process.env.PUBLIC_URL}/images/logo/mm_logo_home.png`}
                        />
                    </div>
                    <div className="main-content-div">
                        <HeaderContainer
                            onHeaderItemClick={this.onHeaderItemClick.bind(this)}
                            items={this.state.HEADER_BUTTONS}
                            // header={this.props.header}
                            dispDate={true}
                            toggleSideNav={this.toggleSideNav}
                            removeFromExport={this.removeFromExportArray.bind(this)}
                            removeAlert={this.removeAlert.bind(this)}
                        />
                        <main style={{ minHeight: window.innerHeight - 70 }}>
                            <ResponsiveContainer style={{ overflowX: 'scroll', overflow: 'auto', position: 'relative' }} offset={70}>
                                {getBreadCrumb()}
                                <div className="main-container">
                                    {/*Routes Comes HERE*/}
                                    <RouteValidator location={this.props.location} privilages={this.props.login.userDetails.privilages}>
                                        <Routes
                                            userid={this.props.login.userDetails.userId}
                                            loggedInUser={this.props.login.userDetails}
                                            privilages={this.props.login.userDetails.privilages}
                                            designationId={this.props.login.userDetails.designationId}
                                            userChannelType={this.props.login.userDetails.channelType}
                                            userEntityType={this.props.login.userDetails.entityId}
                                            exportResponseHandler={this.exportResponseHandler}
                                        />
                                    </RouteValidator>
                                </div>
                                <Popup
                                    type={POPUP_ALIGN.CENTER}
                                    title={"My QR Code"}
                                    isOpen={this.state.openQrCode}
                                    close={this.toggleQRCodeWindow}
                                    minWidth="20%"
                                    component={<QRCode
                                        qrCode={this.props.login.userDetails && this.props.login.userDetails.qrCode}
                                        name={this.props.login.userDetails && this.props.login.userDetails.entity == GLOBAL_CONSTANTS.ENTITY_IDS.MERCHANT ?
                                            this.state.bussinessName : this.props.login.userDetails.name}
                                        msisdn={this.props.login.userDetails && this.props.login.userDetails.msisdn}
                                        showPrint
                                    />}
                                />
                                <NotificationContainer />
                                <AlertContainer
                                    setModalPopup={this.props.setModalPopup}
                                />
                                {/* <LoaderContainer /> */}
                            </ResponsiveContainer>
                            <LoaderContainer />

                        </main>
                    </div>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        login: state.login,
        breadcrumb: state.breadcrumb,
    };
}

const AlertContainer = connect(({ modal }) => ({ modal }))(props => <Alert {...props} />);
const NotificationContainer = connect(({ toast }) => ({ toast }))(props => <Notification {...props} />);
const LoaderContainer = connect(({ loader }) => ({ loader }))(props => <Loader {...props} {...props.loader} />);
const HeaderContainer = connect(({ header }) => ({ header }))(props => <Header {...props} />);

export default withRouter(connect(mapStateToProps, { setModalPopup, addToNotification, removeFromNotification })(Home));
