import React, { useState, useEffect, useRef } from 'react';
import {
    Container,
    Row,
    Col,
    InputGroup,
    InputGroupAddon,
    Input,
    Button
} from 'reactstrap';
import { Link } from 'react-router-dom';
//import { XENTITY } from '../../util/Constants';
//import {XENTITY} from '../../util/Constants'
export let XENTITY;

export default function LoginForm(props) {

    const [userName, setUserName] = useState();
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [agent, setAgent] = useState(false);
    const [passShow, setPassShow] = useState('');
    const [isShow, setIsShow] = useState({ password: false });

    const [type, setType] = useState(5);

    const height = useWindowHeight();
    const handleLoginSubmit = (e) => {
        e.preventDefault();
        if (!userName) {
            setError("Please Enter User Name !");
            return false;
        }
        if (!password) {
            setError("Please Enter Password !");
            return false;
        }
        if (!termsAndConditions) {
            setError("Please accept the terms and conditions !");
            return false;
        }

        props.onSubmit(userName, password)
    };
    const formContainerRef = useRef(null);
    let formHeight = 414;
    useEffect(() => {
        formHeight = formContainerRef.current.offsetHeight;
    }, []);
    useEffect(() => {
        document.cookie = `X-Entity=${type};remember_me=true expires=Thu, 18 Dec 2013 12:00:00 UTC`;
    }, [type]);

    // const checkLogin=(XENTITY)=>{
    //     setAgent(!agent)
    //     if(!agent)
    //     {
    //         XENTITY=2
    //         //date.setTime(date.getTime() + (24 * 60 * 60 * 1000));
    //         document.cookie =`X-Entity=${XENTITY};remember_me=true expires=Thu, 18 Dec 2013 12:00:00 UTC`;

    //     }
    //     else{
    //         XENTITY=5
    //         document.cookie =`X-Entity=${XENTITY};remember_me=true expires=Thu, 18 Dec 2013 12:00:00 UTC`

    //     }
    // }
    function setShow(name) {
        const isShowTmp = { ...isShow };
        console.log("===>", isShowTmp)
        if (name === "password") {
            isShowTmp.password = !isShowTmp.password;
        }
        setIsShow(isShowTmp);
    }

    const handlePassworChange = (name, val) => {
        if (name === "password") {
            if (val && val.length > 0) {
                let str = '';
                const elem = document.getElementById('sampleChar');
                let value = password;
                for (var i = 0; i < val.length; i++) {
                    str += elem.textContent;
                    if (val.length < password.length) {
                        value = value.substring(0, val.length)
                    } else if (i == val.length - 1) {
                        value += val[i];
                    }


                }
                setPassShow(str);
                setPassword(value);
            } else {
                setPassShow('');
                setPassword('');
            }
        }
    }
    const checkLoginNew = (event, XENTITY) => {
        switch (event.target.value) {
            case "merchant":
                setType(5)
                break;
            case "agent":
                setType(2)
                break;
            case "corporate":
                setType(7)
                break;

            default:
                setType(6)
                break;
        }

        // if (event.target.value === "merchant") {
        //     setType(5);

        // }
        // else if (event.target.value === "agent") {
        //     setType(2);
        // }
        // else {
        //     setType(6)
        // }

    }
    const getErrorMsg = () => {

        if (props.message === "Password succesfully changed" || props.message === "You Have Been Logged Out") {
            return (
                <div className='success_login'>
                    {props.message}
                </div>
            );
        }
        else if (props.message)
            return (
                <div className='errorMsg_login'>
                    {props.message}
                </div>
            );

        if (error) {
            return (
                <div className={!error ? 'success_login' : 'errorMsg_login'}>
                    {error}
                </div>
            );
        }

    }

    return (
        <Container fluid>
            <Row>
                {/* linear-gradient(40deg, #F9CF01, #ED7818, #007AC3) */}
                <Col xs={6} lg={8} style={{ height: `${height}px`, background: 'linear-gradient(40deg, #F9CF01, #ED7818, #007AC3)' }} className="bg-secondary login-container"></Col>
                <Col xs={6} lg={4} className="bg-white">
                    <div>
                        <div>
                            <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px`, marginLeft: '100px' }}>
                                <img src={`${process.env.PUBLIC_URL}/images/logo/MM_logo.png`} alt="6d Technologies" style={{ height: '100px', maxWidth: '180px' }} />
                            </div>
                            {/* login form starts here */}
                            <div id="sampleChar" style={{ display: 'none' }}>&#183;</div>
                            <div className="login-form-container bg-white" style={{ boxShadow: 'none' }} ref={formContainerRef}>
                                <div className="login-form-header" style={{ borderBottom: '0' }}>
                                    Welcome to <span className="fw-600">Mobile Money</span>
                                </div>
                                <form onSubmit={handleLoginSubmit} className="login-form">
                                    {getErrorMsg()}
                                    <div>
                                        <InputGroup>
                                            <Input maxlength="40" onChange={(event) => setUserName(event.target.value)}
                                                value={userName}
                                                autoFocus />
                                            <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-user-o" style={{ width: '21px' }} ></i></InputGroupAddon>
                                            <span className="floating-label">User Name</span>
                                        </InputGroup>
                                    </div>
                                    <div className="pt-1">
                                        <InputGroup>
                                            <Input onChange={(event) => handlePassworChange("password", event.target.value)}
                                                value={isShow.password ? password : passShow}
                                                onPaste={(e) => e.preventDefault()} />
                                            <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={() => setShow("password")} ></i></InputGroupAddon>
                                            <span className="floating-label">Password</span>
                                        </InputGroup>
                                    </div>
                                    {/* <div className="d-flex justify-content-start tc-container">
                                        <Input type="checkbox" checked={agent} onClick={() => {
                                            checkLogin(XENTITY)
                                        }} />{' '}
                                        <span>Login as Agent</span>
                                        </div> */}


                                    <Row >
                                        <Col className="pt-1" ><span>Login as</span>{''}</Col>
                                        <Col className="pt-1" ><Input type="radio" name="radio1" value="merchant" checked={type === 5 ? true : false} onClick={(event) => { checkLoginNew(event, XENTITY) }} /><span>Merchant</span>{''}</Col>
                                        <Col className="pt-1" ><Input type="radio" name="radio1" value="agent" checked={type === 2 ? true : false} onClick={(event) => { checkLoginNew(event, XENTITY) }} /><span>Agent</span>{''}</Col>
                                        <Col className="pt-1" ><Input type="radio" name="radio1" value="biller" checked={type === 6 ? true : false} onClick={(event) => { checkLoginNew(event, XENTITY) }} /><span>Biller</span>{''}</Col>
                                        <Col className="pt-1" ><Input type="radio" name="radio1" value="corporate" checked={type === 7 ? true : false} onClick={(event) => { checkLoginNew(event, XENTITY) }} /><span>Corporate</span>{''}</Col>
                                    </Row>
                                    <div className="d-flex justify-content-start tc-container pt-3">
                                        <Input type="checkbox" checked={termsAndConditions} onClick={() => {
                                            setTermsAndConditions(!termsAndConditions);
                                        }} />{' '}
                                        <span>I agree to</span>
                                        {/* <a href="/termsAndConditions" target="_blank" className="terms-and-conditions">Terms and Conditions</a> */}
                                        <Link to="/termsAndConditions" target="_blank" className="terms-and-conditions">Terms and Conditions</Link>
                                    </div>

                                    {
                                        props.isLoading
                                            ? <Button className="w-100 login-button" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }} disabled>
                                                <i className="fa fa-spinner fa-spin"></i>&#09;authenticating...
                                          </Button>
                                            : <Button className="w-100 login-button c-pointer" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }}>Login</Button>}
                                    <div className="login-form-footer">
                                        <div className="d-flex justify-content-start">
                                            <a></a>
                                        </div>
                                        <div className="d-flex justify-content-end">
                                            {/* <a href="/forgotpassword">Forgot password ?</a> */}
                                            <Link to={'/forgotpassword'}>Forgot password ?</Link>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* 
                            <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px` }}>
                                <span>powered by - 6d Technologies</span>
                            </div> */}

                        </div>
                    </div>
                </Col>
            </Row>

        </Container>
    )
}


function useWindowHeight() {
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return height;
}