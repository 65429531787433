import { SET_TOAST_NOTIF, ADD_TO_NOTIFICATION } from '../util/ActionConstants';

export default function (state = {}, action) {
    switch (action.type) {
        case SET_TOAST_NOTIF:
            return action.payload;

        case ADD_TO_NOTIFICATION:
            return {
                hasError: false,
                message: 'You have a new notification!',
                timestamp: new Date().getTime()
            };

        default:
            return state;
    }
}
