import React, { useState,useEffect, Fragment } from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

function TermsAndConditions() {
    const height = useWindowHeight();
    return (
        <div>
            <Container fluid>
                <Row>
                    <Col xs={12} lg={12} style={{ height: `${height}px` }} className="bg-secondary login-container"></Col>
                 </Row>
            </Container>

            <div className="terms-and-conditions-wrapper">
                <div className="terms-and-conditions-container">
                    <div>
                        <h2>eTeeru Privacy Policy</h2>  
                        <p>eteeru is an application developed by Tashi InfoComm Limited, a first private cellular company in Bhutan, a separate entity under Tashi Group of Companies. The company was incorporated on January 23, 2007, under the Companies Act of Bhutan 2016. Under the authorization received from Royal Monetary Authority of Bhutan, under the Payment and Settlement Systems Act for e-money service 2017 and Rules/Regulations thereunder. This document details about the manner we collect, store and use your Personal Information and its security.</p>  
                        <p>Note:<br/>Our privacy policy is subject to change at any time without notice. Though every effort would be made to keep you informed of the changes, if any, to the policy, you are kindly advised to review the policy periodically.</p>
                        <p>By downloading this app you agree to be bound by the terms and conditions of this Privacy Policy. As this policy enables us to provide you a secure and a wholesome experience, we will not be able to register you, if you are not in agreement with these terms.
By mere use of the app, you expressly consent to our use and disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated into and subject to the Terms of Use.</p>
                    
                        <h2>Personal Information</h2>
                        <p>When you use our eteeru app, we collect and store your personal information which is provided by you from time to time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This allows us to provide services and features that most likely meet your needs, and to customize our Website to make your experience safer and easier. More importantly, while doing so we collect personal information from you that we consider necessary for achieving this purpose.</p>
                        <p>During course of your association with us, we collect and store your Personal Information. Personal Information shall mean information that could reasonably be used to identify you personally. This includes, but not limited to the following:</p>

                        <ol>
                            <li><p>Information which you may provide during the registration process such as Name, Phone Number, Email Address, Communication or Permanent Address;</p></li>
                            <li><p>Photograph and/or any other personal document provided during registration or during the profile update such as proof of identity, proof of address;</p></li>
                            <li><p>Usage logs or transactions performed which can identify you as an individual through your customer ID or other relevant unique identifier;</p></li>
                            <li><p>IP Addresses;</p></li>
                            <li><p>Media Access Control address;</p></li>
                            <li><p>Device specific information or identifiers such as IMEI, OS specific identifiers;</p></li>
                            <li><p>Usage logs/Server logs/Cookies having Personal Information;</p></li>
                            <li><p>Any other sensitive personal data or information, such as – Passwords, Financial information, such as details of bank account, credit card, debit card, or other payment instrument details, financial transactions etc.</p></li>
                            
                        </ol>

                        <p>This information can be in electronic or physical format.</p>
                        <p>Any comments, messages, feedback, complaints blogs, scribbles etc. posted /uploaded /conveyed /communicated by you on the public sections of the application becomes published content and is not considered Personal Information subject to this Policy.</p>

                        <h2>Use of Your Information</h2>
                        <p>We use personal information to provide the services you request. To the extent we use your personal information to market to you, we will provide you the ability to opt-out of such uses.</p>
                        <p>We use your personal information to resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest in our products and services, inform you about online and offline offers, products, services, and updates; customize your experience; detect and protect us against error, fraud and other criminal activity; enforce our terms and conditions; and as otherwise described to you at the time of collection.</p>
                        <p>In our efforts to continually improve our product and service offerings, we collect and analyse demographic and profile data about our users’ activity on our app. We identify and use your IP address to help diagnose problems with our server, and to administer our app. Your IP address is also used to help identify you and to gather broad demographic information. We will occasionally ask you to complete optional online surveys.</p>
                        
                        <h2>Sharing personal information</h2>
                        <p>We may share personal information with our other corporate entities and affiliates.
We may use personally identifiable information (email address, name, phone number, credit card / debit card / other payment instrument details, etc.) provided by you to our corporate entities and affiliates during making transactions.</p>
                        <p>We may also disclose anonymised data derived from your personal information to third parties. This disclosure may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing and advertising purposes without your explicit consent.</p>
                        <p>We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an advertisement, posting or other content violates the rights of a third party; or protect the rights, property or personal safety of our users or the general public.</p>
                        
                        <h2>Security Precautions</h2>
                        <p>Our app has stringent security measures in place to protect the loss, misuse, and alteration of the information under our control. Whenever you change or access your account information, we offer the use of a secure server. Once your information is in our possession we adhere to strict security guidelines, protecting it against unauthorized access.</p>
                        
                        <h2>Your Consent</h2>
                        <p>By using the app and/ or by providing your information, you consent to the collection and use of the information you disclose on the app in accordance with this Privacy Policy, including but not limited to Your consent for sharing your information as per this privacy policy.</p>
                        <p>If we decide to change our privacy policy, we will post those changes on this page so that you are always aware of what information we collect, how we use it, and under what circumstances we disclose it.</p>
                        
                        <h2>Information Security and Storage</h2>
                        <ol>
                            <li><p>We have adopted reasonable security practices and procedures, in line with international standards and applicable laws, to include strategic, operational, managerial, technical and physical security controls to safeguard and protect your personal information against unauthorized access and unlawful interception. Additionally, we have adopted measures to ensure that your Personal Information is accessible to our employees or Partner’s employees strictly on the ‘need to know’ basis.</p></li>
                            <li><p>Our applications/website have industry standard security precautions in place to protect the loss, misuse and alteration of your Personal Information with us. Whenever you change or access your account information, we facilitate the use of a secure server. We apply encryption or other appropriate security controls to protect your Personal Information when stored or transmitted by us.</p></li>
                            <li><p>We have taken appropriate steps for the security and protection of all our digital platforms including internal applications, however, we shall not be responsible for any breach of security or the disclosure of Personal Information for reasons outside our control.</p></li>
                        </ol>

                        <h2>Updating of Personal Information</h2>
                        <ol>
                            <li><p>You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consents to provide such information or data. To update your Personal Information with us, you may visit your nearest customer care offices.</p></li>
                        </ol>


                        <h2>Grievance Policy</h2>
                        <p>We have incorporated an escalation squad to ensure that the right teams are addressing your complaints. The customers are requested to adhere to the below escalation structure to ensure a timely redressal of their issues.</p>
                        <ol>
                            <li><p>Complaints Registration:</p></li>
                            <li>
                                <p>Send an email to info@eteeru.com</p>
                                <ul>
                                    <li><p>Contact our Call Center by calling at 7700, OR</p></li>
                                    <li> <p>Visit our nearest Customer Care office.</p></li>
                                </ul>
                                
                            </li>
                            <li>
                                <p>Resolution of Complaints:</p>
                                <ul>
                                    <li><p>First response to a User’s complaint – within 24 business hours</p></li>
                                    <li><p>Resolution – 3 Business Days</p></li>
                                    <li><p>Refund Related – 7-10 business days</p></li>
                                </ul>
                               
                                
                                
                            </li>
                           
                        </ol>
                        <p>Users are proactively informed of delays if any, in the resolution.</p>
                    </div>
                </div>
            </div>
               
        </div>
    );
}

function useWindowHeight() {
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight);
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return height;
}


export default TermsAndConditions;