import { connect } from 'react-redux';
import EnsureLoggedInContainer from '../util/EnsureLoggedInContainer';
import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, MemoryRouter } from 'react-router-dom';
import { MoneyContext } from './util/CustomContexts';
import Login from './login/Login';
import Home from './home/Home';
import { setBaseURL } from '@6d-ui/ajax';
import { BASE_URL, MONEY_ICON } from '../util/Constants';
import { CONSTANTS } from '../util/Constants'
import ForgetPwd from './forgetPwd/ForgetPwd';
import ForcePin from './login/ForcePin'
import ChangePassword from './login/ChangePassword';
import TermsAndConditions from './termsAndConditions/termsAndConditions';

//css imports
import '@6d-ui/fields/build/styles/min/style.min.css';
// import '6d-util-components/build/styles/css/min/styles.min.css';
import '@6d-ui/data-table/build/styles/min/style.min.css';
import '@6d-ui/buttons/build/styles/min/style.min.css';
import '@6d-ui/popup/build/styles/min/style.min.css'
import '@6d-ui/ui-components/build/styles/min/style.min.css';
import '@6d-ui/form/build/styles/min/style.min.css';
import './styleSheet/common/form.css';
import './styleSheet/out/style.css';
import './styleSheet/out/skeleton.css';
import './Login.css';
import LoginForm from './login/LoginForm';

class App extends Component {
  componentDidMount() {
    // console.log("ENV READER", process.env.REACT_APP_BL_URL, process.env.REACT_APP_MONEY_ICON);
    // console.log("CONSTANTS READER", BASE_URL, MONEY_ICON);
    setBaseURL(BASE_URL);
  }
  render() {
    return (
      <MoneyContext.Provider value={MONEY_ICON}>
        {/* <MemoryRouter basename={process.env.PUBLIC_URL}> */}
        <BrowserRouter basename={process.env.PUBLIC_URL}>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/forceChangePassword" component={ChangePassword} />
            <Route path="/forcePin" component={ForcePin} />
            <Route path="/forgotpassword" component={ForgetPwd} />
            <Route path="/LoginForm" component={LoginForm} /> 
            <Route path="/termsAndConditions" component={TermsAndConditions} />
            <EnsureLoggedInContainer>
              <Switch>
                <Route path="/" component={Home} />
              </Switch>
            </EnsureLoggedInContainer>
          </Switch>
        </BrowserRouter>
        {/* </MemoryRouter> */}
      </MoneyContext.Provider>
    );
  }
}

function mapStateToProps(state) {
  return { login: state.login };
}

export default connect(mapStateToProps)(App);
