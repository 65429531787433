export const { BASE_URL, RULEENGINE_URL, MONEY_ICON, LMS_URL, NO_OF_YEARS } = window;
// const {REACT_APP_BL_URL, REACT_APP_MONEY_ICON} = process.env;
// export const { RULEENGINE_URL, BASE_URL, MONEY_ICON } = { 
//     BASE_URL:REACT_APP_BL_URL, 
//     MONEY_ICON:REACT_APP_MONEY_ICON, 
//     RULEENGINE_URL:"" 
// };
export const LOGIN = "login";
export const LOGOUT = "logout";
export const CHANGE_PSWD = "changePswd";
export const SET_LOADING = "set_loading";
export const SET_TOAST_NOTIF = "set_toast_notif";
export const SET_MODAL_POPUP = "set_modal_popup";
export const SET_HEADER = "set_header";
export const SET_BREAD_CRUMB = "set_breadcrumb";


export const GLOBAL_CONSTANTS = {
    INITIAL_ROW_COUNT: 20,
    DATE_FORMAT: "YYYY-MM-DD",
    GET_PRODUCTS_URL: "/products/v1/getList",
    GET_ROLES_URL: "/role/v1/getRoles",
    FORM_MODAL: {
        SearchFilter: 1,
        Create: 2,
        Edit: 3,
        View: 4,
        Delete: 5
    },
    BL_RESULT_CODES: {
        SUCCESS: "0"
    },
    PRODUCT_WITH_SIM_MSISDN: 2,
    ENTITY_IDS: {
        CHANNEL_PARTNER: 2,
        SUBSCRIBER: 1, //Customer
        MERCHANT: 5,
        OPERATOR: 4, // Admin USER
        BILLER: 6,
        EMPLOYEE: 3,
        CORPORATE :7,
        EVENTS:8
    },
    LEVEL_ID: {
        OPERATOR: 1,
        SUPER_AGENT : 2,
        MASTER_AGENT :3,
        AGENT : 4
    },
    SERVICE_IDS: {
        TOPUP: 24,
        BILL_PAYMENT: 26,
        LEASE_LINE_PAYMENT: 38,
        CASH_IN: 1,
        CASH_OUT: 2,
        CASH_OUT_BANK: 31,
        CASH_IN_BANK: 30,
        REDEEM_COUPON: 14,
        DISTRIBUTE_SALARY:67,
        ELECTRICITY_BILL:113,
        TAX_PAY:112,
        LIFE_INSURANCE:99,
        LIFE_ANNUITY:102,
        LOAN_REPAYMENT:97,
        WATER_BILL_PAYMENT:153,
        LAND_TAX_PAYMENT:159
    },
}


// export const AUTH_KEY = 'Basic aW50ZXJmYWNlX3dlYl91c2VyOjk4OHNkc2RAdHU=';
export const AUTH_KEY = 'Basic YXBpQHdlYiRoc2hhazphcGlAd2Vi';
export const CHANNEL = "WEB";
export let XENTITY = 2;

export const LOGIN_URL = "/v1/api/auth/at/signin";
// export const CHECK_MSISDN_URL = "/v1/accountholder/userinfo/msisdn/";
export const CHECK_MSISDN_URL = "/v1/accountholder/check-existance/msisdn/";
//export const LOGIN_URL = "/v1/api/auth/signin";
export const LOGOUT_URL = "/v1/api/auth/logout";
export const AUTH_URL = `${BASE_URL}/v1/api/auth/authorize`;
// export const AUTH_URL =     "http://10.0.6.25:5001/v1/api/auth/authorize"
// export const CHANGE_PSWD_URL = "/ChangePassword/v1/changePwd";
export const CHANGE_PSWD_URL = "/v1/password/change";
export const CHANGE_PIN_URL = "/v1/pin/change";
export const FORGET_PSWD_URL = "/ForgetPassword/v1/forgetPwd";
export const CHANGE_PSWD_POLICY_URL = "v1/upfront/input/password/validation";
export const CHANGE_PIN_POLICY_URL ="v1/upfront/input/pin/validation";

export const ENCYPT_KEY = "sdsd34545sdfasd232sds334";
export const ENCYPT_KEY_AES = "S18d443nc6y9710n";

export const SYSTEM_WALLET_ID = 1;
export const CITIZENSHIP_ID_CARD = 7;
export const COMMISSION_WALLET_ID = 5;
export const E_WALLET_ID = 4;

export const MSISDN_REGEX = /^(77|17|16)([0-9]{6})$/
export const OTP_VERIFICATION_FAILED = 136;
export const FILE_SIZE =5120;
export const CONSTANTS = {
    WELCOME_BONUS: {
        GET_URL: "/v1/welcomebonus/",
        POST_URL: "v1/welcomebonus/",
        PUT_URL: "v1/welcomebonus/",
        DELETE_URL: "v1/welcomebonus/"
    },
    SYSTEM_CONFIG: {
        LIST_URL: "/ConfigParam/v1/search",
        DELETE_URL: "/ConfigParam/v1/delete",
        CREATE_URL: "/ConfigParam/v1/create",
        EDIT_URL: "/ConfigParam/v1/update"
    },
    COMPANY_TYPE: {
        LIST_URL: "/CompanyType/v1/search/",
        DELETE_URL: "/CompanyType/v1/delete/",
        CREATE_URL: "/CompanyType/v1/create/",
        EDIT_URL: "/CompanyType/v1/update/"
    },
    DOCTYPE: {
        LIST_URL: "/DoccumentType/v1/search/",
        DELETE_URL: "/DoccumentType/v1/delete/",
        CREATE_URL: "/DoccumentType/v1/create/",
        EDIT_URL: "/DoccumentType/v1/update/"
    },
    SERVICE_CLASS: {
        LIST_URL: "/serviceClass/v1/getlist",
        DELETE_URL: "/serviceClass/v1/delete?surveyId=",
        CREATE_URL: "/serviceClass/v1/create",
        EDIT_URL: "/serviceClass/v1/update"
    },
    CHANGE_PSWD: {
        CHANGE_PSWD_POLICY_URL : "v1/upfront/input/password/validation",
        CHANGE_PSWD_URL: "/v1/password/change"
    },
    CHANGE_PIN: {
        CHANGE_PIN_POLICY_URL :"v1/upfront/input/pin/validation",
        CHANGE_PIN_URL: "/v1/pin/change"
    },
    CHANNEL_PARTNERS: {
        CREATE_URL: "/entity/v1/create",
        UPDATE_URL: "/entity/v1/update",
        DELETE_URL: "/role/v1/delete",
        SEARCH_URL: "/entity/v1/getList",
        VIEW_URL: "/entity/v1/view",
        LIST_ALL_FILES: '/file/v1/listAllFiles',
        LIST_PRODUCTS: '/products/v1/getProductList',
        DELETE_PRODUCTS: '/products/v1/delete',
        UPDATE_PRODUCTS: '/products/v1/update',
        ADD_PRODUCTS: '/products/v1/add',
        FILE_VIEW: '/file/v1/view',
        FILE_STORE: '/file/v1/store',

        GET_LOCATION_TYPES: '/entityLocation/v1/getAllEntityChildLocationTypes?entityId=',
        GET_LOCATIONS_BY_ENTITY: '/entityLocation/v1/getEntityChildLocations',

        LIST_ALL_PRODUCTS: '/products/v1/getList',

        SAVE_DOCUMENT: '/file/v1/create?entityId=',
        DELETE_DOCUMENT: '/file/v1/delete',
        DEACTIVATE_URL: '/entity/v1/deactivate',
        ACTIVATE_URL: '/entity/v1/activate',
        VISIT_DETAILS: "/checkin/v1/view",
        VISIT_HISTORY: "/checkin/v1/history",
        GET_CHILDREN: '/entity/v1/getChildList'
    },
    SALES_HIERARCHY: {
        LIST_URL: "/salestructure/v1.0/view",
        VIEW_NODE_URL: "/salestructure/v1.0/viewNodeDetails",
        CREATE_NODE_URL: "/salestructure/v1.0/create",
        UPDATE_NODE_URL: "/salestructure/v1.0/update",
        ADD_DESIG_URL: "/designation/v1/create",
        UPDATE_DESIG_URL: "/designation/v1/update",

        OP_NODE_TYPE: 1,
        BU_NODE_TYPE: 2,
        EN_NODE_TYPE: 3
    },
    USER_MGMNT: {
        LIST_URL: "/v1/userMgnt",
        CREATE_URL: "/v1/userMgnt",
        DELETE_URL: "/v1/userMgnt/delete/",
        VIEW_URL: "/v1/userMgnt/",
        EDIT_URL: "/v1/userMgnt/",
        CHANGE_STATUS: "/user/v1/changeStatus?user=",
        RESETPASSWORD_URL: "/user/v1/resetPassword?userId=",
        ENTITY_GET_URL: '/v1/entity',
        NATIONALITY_GET: '/v1/kyc/nations'
    },
    ROLES: {
        CREATE_URL: "/v1/role",
        UPDATE_URL: "/v1/role",
        DELETE_URL: "/role/v1/delete",
        SEARCH_URL: "/v1/role",
        VIEW_URL: "/v1/role/",
        GET_FEATURES: "/v1/module"
    },
    MESSAGE_TEMPLATES: {
        LIST_URL: '/v1/templates/',
        DELETE_URL: '/v1/templates/1001/E',
        GET_MSG_TAGS: '/v1/templates/tags',
        EDIT_URL: '/v1/templates/',
        CREATE_URL: '/v1/templates/'
    },
    ERROR_CODE_URL: '/v1/statuscodes',

    SERVICE_FEE: {
        GET_SERVICES: '/v1/serviceManagement/',
        GET_FEES: '/v1/service/fees',
        UPDATE_FEES: '/v1/service/fee/',
        CREATE_FEES: '/v1/service/fee/',
        DELETE_FEES: '/v1/service/fee/',
        GET_PROFILES: '/v1/profiles'
    },
    ACCOUNT_HOLDER: {
        LIST_URL: "/v1/accountholder",
        VIEW_URL: "/v1/accountholder/",
        REGISTER_ACCOUNT: "/v1/accountholder/register",
        GET_LEVEL: "/v1/hierarchy/levels",
        GET_ENTITIES: "/v1/entity/all",
        GET_KYC_FEILDS: "/v1/fields/getKycFields?",
        GET_NATIONS: "/v1/kyc/nations",
        GET_BANK: "/v1/kyc/banks",
        GET_LANGUAGES: "/v1/kyc/languages",
        GET_ID_TYPE: "/v1/kyc/identity",
        GET_BUSSINESS_TYPE: "/v1/kyc/business",
        GET_DOCUMENT_TYPE_OPTIONS: "/v1/documents/types",
        RESET_PIN: "/v1/pin/reset/",
        RESET_PASSWORD: "/v1/password/reset",
        LIFECYCLE_SWITCH: "/v1/lifecycles/switch",
        LIFECYCLE: "/v1/lifecycles/",
        DOCUMNET_CREATE: "/v1/documents/store",
        // BALANCE: "/v1/wallets/balance/",
        BALANCE: "/v1/wallets/balance/admin/",
        LIST_ALL_DOCUMENTS: "/v1/documents/listAllFiles/",
        EDIT_DOCUMENTS: "/v1/documents/addnew",
        VIEW_DOCUMENTS: "/v1/documents/view/",
        DELETE_DOCUMENTS: "/v1/documents/delete/",
        GET_PARENT: "v1/accountholder/hierarchy/channels/",
        GET_TRANSACTIONS: "v1/history",
        GET_STATES: "v1/kyc/states",
        GET_DISTRICTS: "v1/kyc/district/",
        GET_VILLAGES: "v1/kyc/villages/",
        CHANGE_MSISDN: "v1/accountholder/userinfo/changeMsisdn",
        CHANGE_EMAIL: "v1/accountholder/userinfo/changeEmail",
        GET_KYC_DETAILS: "v1/accountholder/userinfo/msisdn/",
        DOWNLOAD_XLS: "",
        DOWNLOAD_CSV: "",
        GET_TRANSACTION_REPORT: "v1/myreport",
        GET_BUSSINESS_DETAILS: "v1/accountholder/businessinfo",
        GET_ALL_BUSSINESS_TYPES : "v1/accountholder/businessinfo/getall",
        DEREGISTER_AGENT_URL :'/v1/deregister/manual/agent',
        DEREGISTER_CUSTOMER_URL :'/v1/deregister/manual/customer',
        DEREGISTER_MERCHANT_URL :'/v1/deregister/manual/merchant',
        REQUEST_LETTER_UPLOAD_URL : 'v1/deregister/manual/requestLetterUpload',
        OFFICE_VERIFICATION_GET:"v1/offline-verification/view/",
        OFFICE_VERIFICATION:"v1/offline-verification/action",
        GET_CONTESTANT_DATA: "v1/votingEvent/userinfo?",
        UPDATE_CONTESTANT:"v1/eventContestant/update",
        CREATE_CONTESTANT:"v1/eventContestant/create",
        DELETE_CONTESTANT:"v1/eventContestant/delete/"
    },
    PROFILE: {
        PROFILE_URL: "/v1/profiles/",
        GET_ROLE_URL: "/v1/role/",
        GET_SERVICE_URL: "/v1/serviceManagement",
        GET_ENTITY_URL: "/v1/entity/all/"
    },
    SYSTEM_WALLETS: {
        LIST_URL: "/v1/system/stock/report",
        GET_WALLETS_URL: "/v1/wallets/types/system",
        ADD_STOCK_URL: "/v1/system/stock/credit"
    },
    FLOAT_TRANSFER: {
        // LIST_URL: "/v1/accountholder/hierarchy/childs?userId=",
        LIST_URL: "v1/accountholder/hierarchy/getFloatTransfer/",
        CONFIRM_TRANSFER: "v1/float/transfer",
        CONFIRM_WITHDRAWL: "v1/float/withdraw"

    },
    WITHDRAWAL:
    {
        LIST_URL: "v1/accountholder/hierarchy/getFloatWithdraw/",
        CONFIRM_WITHDRAWL: "v1/float/withdraw",
        GENERATE_OTP:"v1/generate/otp?msisdn=",
        VERIFY_OTP:"v1/generate/otp/verify/internal?msisdn=",

    },
    AGENT_TRANSFER: {
        LIST_URL: "v1/accountholder/hierarchy/getSiblings/",
        //LIST_URL: "v1/accountholder/hierarchy/siblings?userId=",
        CONFIRM_TRANSFER: "v1/float/transfer/inlevel/"
    },

    CASHIN: {
        LIST_URL: "/v1/accountholder/userinfo/msisdn/",
        CASHIN_URL: "/v1/cashin/agent/"
    },
    CASHOUT: {
        LIST_URL: "/v1/accountholder/userinfo/msisdn/",
        PIN_URL: "/v1/cashout/agent",
        CASHOUT_URL: "/v1/cashout/agent/"
    },
    OFFLINEBILLING: {
        UPLOAD_FILE: "/v1/pay/utility/offline/upload",
        LIST_URL: "/v1/pay/utility/offline",
        VIEW_URL: "/v1/pay/utility/offline/view?fileId=",
        PAY_OFFLINE: "/v1/pay/utility/offline/mark/paid/"
    },
    DISTRIBUTE_SALARY: {
        UPLOAD_FILE: "/v1/bulkPayment/offline/upload",
        LIST_URL: "/v1/bulkPayment/offline/",
        VIEW_URL: "/v1/bulkPayment/offline/view?fileId=",
        DELETE_URL:"/v1/bulkPayment/offline/deleteFile/",
        PAY_URL :"/v1/bulkPayment/offline/paynow/",
        VIEW_ERRORS_URL:"/v1/bulkPayment/offline/viewIncompleteData?fileId=",
        EDIT_ERRORS_URL:"/v1/bulkPayment/offline/updateTempDetails",
        DELETE_ERRORS_URL:"/v1/bulkPayment/offline/deleteTempDetails/",
        REFRESH_STATUS_URL:"/v1/bulkPayment/offline/refreshFileStatus/",
        EDIT_FILE_DETAILS:"/v1/bulkPayment/offline/updateFileDetails",
        DELETE_FILE_DETAILS:"/v1/bulkPayment/offline/deleteFileDetails/"
    },

    TRANSFER_COMMISSION:{
        TRANSFER_COMMISSION_URL:"/v1/walletTransfer"
    },
	
    REPORTS: {
        TRANSACTION_REPORT: "/v1/transactionreport",
        TRANSACTION_REPORT_DOWNLOAD_CSV: '/v1/transactionreport/export/csv',
        TRANSACTION_REPORT_DOWNLOAD_XLS: '/v1/transactionreport/export/xls',
        AGENT_REPORT: "/v1/agentReport",
        AGENT_REPORT_DOWNLOAD_CSV: "v1/agentReport/export/csv",
        AGENT_REPORT_DOWNLOAD_XLS: "v1/agentReport/export/xls",
        USER_ACCOUNT_REPORT: "v1/userAccountReport/",
        USER_ACCOUNT_DOWNLOAD_CSV: "v1/userAccountReport/export/csv",
        USER_ACCOUNT_DOWNLOAD_XLS: "v1/userAccountReport/export/xls",
        USER_BALANCE_REPORT: "v1/userBalanceReport",
        USER_BALANCE_DOWNLOAD_CSV: "v1/userBalanceReport/export/csv",
        USER_BALANCE_DOWNLOAD_XLS: "v1/userBalanceReport/export/xls",
        MERCHANT_REPORT: "/v1/merchantreport",
        MERCHANT_DOWNLOAD_CSV: "v1/merchantreport/export/csv",
        MERCHANT_DOWNLOAD_XLS: "v1/merchantreport/export/xls",

        SETTLEMENT_REPORT: "v1/settlementreport",
        SETTLEMENT_DOWNLOAD_CSV: "v1/settlementreport/export/csv",
        SETTLEMENT_DOWNLOAD_XLS: "v1/settlementreport/export/xls",
        GET_WALLET_TYPES: "v1/wallets/types",
        CHANNEL_URL: "v1/channels",
        VIEW_TRANSACTION_REPORT: "v1/transactioninfo",
        BALANCE_SETTLEMENT_REPORT: "v1/balanceSettlementReport",
        CUSTOMER_CARE_REPORT: "v1/customerCareReport",
        CUSTOMER_CARE_REPORT_DOWNLOAD_CSV: '/v1/customerCareReport/export/csv',
        CUSTOMER_CARE_REPORT_DOWNLOAD_XLS: '/v1/customerCareReport/export/xls',
        OFFLINE_BILLER_REPORT:"v1/offlinebillerreport",
        OFFLINE_BILLER_REPORT_DOWNLOAD_CSV: 'v1/offlinebillerreport/export/csv',
        OFFLINE_BILLER_REPORT_DOWNLOAD_XLS: 'v1/offlinebillerreport/export/xls',
        GET_BILLERS:"v1/accountholder?entityType=",
        DEREGISTERED_USER_REPORT :'v1/deregistartionReport',
        VIEW_BILLER_TXN_WISE_TXN_REPORT :'v1/biller/transactionwise',
        BILLER_TXN_WISE_TXN_REPORT_DOWNLOAD_CSV :'v1/biller/transactionwise/export/csv',
        BILLER_TXN_WISE_TXN_REPORT_DOWNLOAD_XLS :'v1/biller/transactionwise/export/xls',
        VIEW_BILLER_CASHOUT_HISTORY_REPORT :'v1/biller/history',
        BILLER_CASHOUT_HISTORY_REPORT_DOWNLOAD_CSV :'v1/biller/history/export/csv',
        BILLER_CASHOUT_HISTORY_REPORT_DOWNLOAD_XLS :'v1/biller/history/export/xls',
        VIEW_BILLER_DATEWISE_TRANSACTION_REPORT :'v1/biller/datewise',
        BILLER_DATEWISE_TRANSACTION_REPORT_DOWNLOAD_CSV :'v1/biller/datewise/export/csv',
        BILLER_DATEWISE_TRANSACTION_REPORT_DOWNLOAD_XLS :'v1/biller/datewise/export/xls',
        REQUEST_LETTER_VIEW :'v1/deregister/view/',
        DEREGISTERED_USER_REPORT_DOWNLOAD_XLS : 'v1/deregistartionReport/export/xls',
        DEREGISTERED_USER_REPORT_DOWNLOAD_CSV : 'v1/deregistartionReport/export/csv',
        VIEW_CORPORATE_SALARY_PAYMENT_REPORT:'v1/corporateSalaryPayment',
        CORPORATE_SALARY_PAYMENT_REPORT_DOWNLOAD_CSV:'v1/corporateSalaryPayment/export/csv',
        CORPORATE_SALARY_PAYMENT_REPORT_DOWNLOAD_XLS:'v1/corporateSalaryPayment/export/xls',
        VIEW_CORPORATE_TRANSACTION_REPORT:'v1/corporateTransactionReport',
        CORPORATE_TRANSACTION_REPORT_DOWNLOAD_CSV:'v1/corporateTransactionReport/export/csv',
        CORPORATE_TRANSACTION_REPORT_DOWNLOAD_XLS:'v1/corporateTransactionReport/export/xls',
        VIEW_DUEAMOUNT_REPORT:'v1/dueAmountCollection',
        DUEAMOUNT_REPORT_DOWNLOAD_CSV:'v1/dueAmountCollection/export/csv',
        DUEAMOUNT_REPORT_DOWNLOAD_XLS:'v1/dueAmountCollection/export/xls',
        VIEW_ELECTRICITY_REPORT:'/v1/electricitybillpaymentreport',
        ELECTRICITY_REPORT_XLS:'/v1/electricitybillpaymentreport/export/xls',
        ELECTRICITY_REPORT_CSV:'/v1/electricitybillpaymentreport/export/csv',
        VIEW_TAX_REPORT:'/v1/taxpaymentrreport',
        TAX_REPORT_XLS:'/v1/taxpaymentrreport/export/xls',
        TAX_REPORT_CSV:'/v1/taxpaymentrreport/export/csv',
        RICB_LOAN_REPAYMENT_SUCCESS_REPORT  :'v1/loanrepaymentreport?statusType=S',
        RICB_LOAN_REPAYMENT_SUCCESS_REPORT_DOWNLOAD_XLS : 'v1/loanrepaymentreport/success/export/xls',
        RICB_LOAN_REPAYMENT_SUCCESS_REPORT_DOWNLOAD_CSV : 'v1/loanrepaymentreport/success/export/csv',
        RICB_LOAN_REPAYMENT_FAILURE_REPORT  :'v1/loanrepaymentreport?statusType=F',
        RICB_LOAN_REPAYMENT_FAILURE_REPORT_DOWNLOAD_XLS :'v1/loanrepaymentreport/failure/export/xls',
        RICB_LOAN_REPAYMENT_FAILURE_REPORT_DOWNLOAD_CSV :'v1/loanrepaymentreport/failure/export/csv',
        RICB_LOAN_INSURANCE_SUCCESS_REPORT  :'v1/lifeinsurancereport?statusType=S',
        RICB_LOAN_INSURANCE_SUCCESS_REPORT_DOWNLOAD_XLS :'v1/lifeinsurancereport/success/export/xls',
        RICB_LOAN_INSURANCE_SUCCESS_REPORT_DOWNLOAD_CSV :'v1/lifeinsurancereport/success/export/csv',
        RICB_LOAN_INSURANCE_FAILURE_REPORT  :'v1/lifeinsurancereport?statusType=F',
        RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_XLS :'v1/lifeinsurancereport/failure/export/xls',
        RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_CSV :'v1/lifeinsurancereport/failure/export/csv',
        RICB_LOAN_ANNUITY_SUCCESS_REPORT  :'/v1/lifeannuityreport?statusType=S',
        RICB_LOAN_ANNUITY_SUCCESS_REPORT_DOWNLOAD_XLS :'v1/lifeannuityreport/success/export/xls',
        RICB_LOAN_ANNUITY_SUCCESS_REPORT_DOWNLOAD_CSV :'v1/lifeannuityreport/success/export/csv',
        RICB_LOAN_ANNUITY_FAILURE_REPORT  :'v1/lifeannuityreport?statusType=F',
        RICB_LOAN_ANNUITY_FAILURE_REPORT_DOWNLOAD_XLS :'v1/lifeannuityreport/failure/export/xls',
        RICB_LOAN_ANNUITY_FAILURE_REPORT_DOWNLOAD_CSV :'v1/lifeannuityreport/failure/export/csv',
        VIEW_MERCHAT_TOPUP_REPORT:'/v1/merchantTopupReport',
        MERCHANT_TOPUP_REPORT_CSV:'/v1/merchantTopupReport/export/csv',
        MERCHANT_TOPUP_REPORT_XLS:'/v1/merchantTopupReport/export/xls',
        VOTING_EVENT_PAYMENT_REPORT:'v1/votingEventPaymentReport',
        VOTING_EVENT_PAYMENT_REPORT_CSV:'v1/votingEventPaymentReport/export/csv',
        VOTING_EVENT_PAYMENT_REPORT_XLS:'v1/votingEventPaymentReport/export/xls',

        USER_SPECIFIC_REPORT:'v1/getUsers/fullname/transactionreport',
        LOAD_USERS:'v1/getUsers/fullname/',
        USER_SPECIFIC_REPORT_XLS:'v1/getUsers/fullname/transactionreport/export/xls',
        USER_SPECIFIC_REPORT_CSV:'v1/getUsers/fullname/transactionreport/export/csv',

        WATER_BILL_REPORT:'v1/waterBillPaymentReport',
        WATER_BILL_REPORT_CSV:'v1/waterBillPaymentReport/export/csv',
        WATER_BILL_REPORT_XLS:'v1/waterBillPaymentReport/export/xls',


        LAND_TAX_REPORT:'v1/landTaxPaymentReport',
        LAND_TAX_REPORT_CSV:'v1/landTaxPaymentReport/export/csv',
        LAND_TAX_REPORT_XLS:'v1/landTaxPaymentReport/export/xls',

        REGION_WISE_REPORT:'v1/region-wise-report',
        REGION_WISE_REPORT_CSV:'v1/region-wise-report/export/csv',
        REGION_WISE_REPORT_XLS:'v1/region-wise-report/export/xls',

        BT_RECHARGE_REPORT:'v1/btRecharge/report',
        BT_RECHARGE_REPORT_CSV:'v1/btRecharge/report/export/csv?',
        BT_RECHARGE_REPORT_XLS:'v1/btRecharge/report/export/xls?',
    },
    // TAX_MANAGEMENT: {
    //     GET_FEES: '/v1/service/fees',
    //     UPDATE_FEES: '/v1/service/fee/',
    //     CREATE_FEES: '/v1/service/fee/',
    //     DELETE_FEES: '/v1/service/fee/'
    // },
    MANAGE_TAX: {
        GET_SERVICES: '/v1/serviceManagement/',
        GET_TAX: '/v1/service/tax',
        UPDATE_TAX: '/v1/service/tax/',
        CREATE_TAX: '/v1/service/tax/',
        DELETE_TAX: '/v1/service/tax/',
        GET_PROFILES: '/v1/profiles'
    },
    COMMISSION: {
        GET_COMMISSION: '/v1/commission',
        COMMISSION_CREATE_UPDATE_DELETE_URL: '/v1/commission/',
        COMMISSION_SHARE: 'v1/hierarchy/parents/'
    },
    BROADCAST: {
        CREATE: "/v1/servicebroadcast",
        DELETE: "/v1/servicebroadcast",
        VIEW: "/v1/servicebroadcast"
    },
    SERVICE:
    {
        GET_SERVICES: "/v1/serviceManagement/",
        VIEW_SERVICE: "/v1/serviceManagement/view/",
        UPDATE_SERVICE: "/v1/serviceManagement/"

    },
    WALLET_PRIVILEGES:
    {
        GET_WALLETS: "/v1/wallets/types/restricted",
        GET_SERVICES: '/v1/serviceManagement',
        CREATE: "/v1/wallets/privileges/",
    },
    ERROR_CODE_URL: {
        CREATE_URL: '/v1/statuscodes/create',
        EDIT_URL: '/v1/statuscodes/edit',
        DELETE_URL: '/v1/statuscodes/delete/',
        LIST_URL: '/v1/statuscodes/'
    },
    FORGOT: {
        PASSWORD: "/v1/password/forgot",
        NEWPASSWORD: "/v1/password/forgot/commit"
    },
    CASHOUT_TO_BANK: {
        BANK_INFO: "/v1/accountholder/bankinfo/",
        ALL_BANK_INFO:"/v1/kyc/banks",
        CASHOUT: "/v1/cashout/bank",
        CREATE_AUTOPAYMENT: "/v1/UserAutoPayments/create",
        VIEW_AUTOPAYMENT: "/v1/UserAutoPayments/",
        EDIT_AUTOPAYMENT: "/v1/UserAutoPayments/edit",
        DELETE_AUTOPAYMENT: "/v1/UserAutoPayments/delete/"
    },

    CASHOUT_IN_BANK: {
        BANK_INFO: "/v1/accountholder/bankinfo/",
        BANK_LIST: "/v1/kyc/banks",
        GET_OTP: "/v1/cashin/bank/",
    },

    REDEEM_COUPON: {
        REDEEM_COUPON: "/v1/consume/unreg/p2p",
        GET_AMOUNT_URL: "v1/transactioninfo?transactionId="
    },

    SUBSCRIPTION: {
        VIEW: "/v1/subscriptionCharge/",
        CREATE: "/v1/subscriptionCharge",
        DELETE: "/v1/subscriptionCharge",
        UPDATE: "/v1/subscriptionCharge"
    },
    COMMISSIONBALANCE: {
        VIEW: "/v1/monthlyCommission/",
        CREATE: "/v1/monthlyCommission",
        DELETE: "/v1/monthlyCommission/",
        UPDATE: "/v1/monthlyCommission"
    },
    REFERRALBONUS: {
        VIEW: "/v1/referalbonus/",
        CREATE: "/v1/referalbonus",
        DELETE: "/v1/referalbonus/",
        UPDATE: "/v1/referalbonus"
    },
    TOPUP: {
        FETCH_DETILS: "/v1/accountholder/userinfo/msisdn/",
        TOPUP_URL: "v1/topup",
        BROWSE_PLANS: "v1/datapackages/pack",
        GET_CHARGES: "v1/getcharges",
        APPLY_COUPON: "v1/getdiscount"
    },
    BILLPAYMENT: {
        BILL_PAYMENT_URL: "v1/billpayment",
        GET_AMOUNT: "v1/billpayment/getOutstandingBalance",
        CREATE_AUTOPAYMENT: "/v1/UserAutoPayments/create",
        VIEW_AUTOPAYMENT: "/v1/UserAutoPayments/",
        EDIT_AUTOPAYMENT: "/v1/UserAutoPayments/edit",
        DELETE_AUTOPAYMENT: "/v1/UserAutoPayments/delete/"
    },
    LEASELINE: {
        LEASELINE_PAYMENT_URL: "v1/leaseline",
        GET_AMOUNT: "v1/leaseline/getOutstandingBalance",
        // GET_AMOUNT: "v1/billpayment/getOutstandingBalance",
        CREATE_AUTOPAYMENT: "/v1/UserAutoPayments/create",
        VIEW_AUTOPAYMENT: "/v1/UserAutoPayments/",
        EDIT_AUTOPAYMENT: "/v1/UserAutoPayments/edit",
        DELETE_AUTOPAYMENT: "/v1/UserAutoPayments/delete/"
    },
    DASHBOARD: {
        GET_BALANCE: "/v1/wallets/balance/admin/",
        GET_MONTHLY_TOTAL: "/v1/merchantSummaryReport/monthlyTotal",
        GET_YEARLY_TOTAL: "/v1/merchantSummaryReport/yearlyTotal",
        GET_ENTITY_TOTAL: "/v1/adminSummaryReport/entityTotal",
        WALLET_SUMMARY: "/v1/adminSummaryReport/walletSummary",
        TREND_GRAPH: "/v1/merchantSummaryReport/datewise/",
        GET_MONTHLY_TOTAL_AGENT: "/v1/agentSummaryReport/monthlyTotal",
        GET_YEARLY_TOTAL_AGENT: "/v1/agentSummaryReport/yearlyTotal",
        GET_REWARD_POINTS_REPORT :"/v1/merchantLoyaltyManagement/getMerchantQuota",
        GET_CAMPAIGN_RUNNING :"/v1/merchantLoyaltyManagement/getMerchantPromotions",
        TREND_GRAPH_BILLER: "v1/billersummaryreport/trend/",
        GET_MONTHLY_TOTAL_BILLER: "v1/billersummaryreport/monthlytotal",
        GET_YEARLY_TOTAL_BILLER: "v1/billersummaryreport/yearlytotal",
        GET_TOP_BILLERS: "/v1/billersummaryreport/toptransactionbill",
        GET_Biller_CASHOUT_BALANCE: "v1/billercashoutsummary",
        GET_MONTHLY_TOTAL_CORPORATE:"/v1/corporateSummaryReport/monthlyTotal",
        GET_YEARLY_TOTAL_CORPORATE:"/v1/corporateSummaryReport/yearlyTotal",
        TREND_GRAPH_CORPORATE:"/v1/corporateSummaryReport/trend/",
        CORPORATE_CASHIN:"/v1/corporateSummaryReport/totalCashIn/",
        CORPORATE_WEEKLY_GRAPH:"v1/corporateSummaryReport/weeklyTotal"
    },
    WELCOME: {
        VIEW: "v1/welcomeMessage/",
        CREATE: "v1/welcomeMessage",
        DELETE: "v1/welcomeMessage",
        UPDATE: "v1/welcomeMessage"
    },
    REQUEST_MONEY: {
        VIEW: "v1/request/money",
        REQUEST: "v1/request/money",
        // GET_BALANCE: "/v1/wallets/balance/",
        GET_BALANCE: "/v1/wallets/balance/admin/",
       
    },
    ELECTRICITY_PAY:{
        FETCH_DETAILS:"v1/electricitybillpayment/fetch/",
        PAY_BILL:"v1/electricitybillpayment/paybill "

    },
    TAX_PAY:{
        FETCH_DETAILS:"v1/taxpayment/fetch/",
        PAY_BILL:"/v1/taxpayment/paytax"

    },
    LIFE_INSURANCE:{
        FETCH_DETAILS:"v1/lifeinsurance/fetchdetails?policynumber=",
        PAY_BILL:"v1/lifeinsurance/makepayment"

    },
    LIFE_ANNUITY:{
        FETCH_DETAILS:"v1/lifeannuity/fetchdetails?policynumber=",
        PAY_BILL:"v1/lifeannuity/makepayment",
    },
    LOAN_REPAYMENT:{
        FETCH_DETAILS:"v1/loanrepayment/fetchdetails?loanaccnumber=",
        PAY_BILL:"v1/loanrepayment/makepayment",
    },
    TASK: {
        FETCH_DROPDOWN_LIST: "v1/notification/viewAll",
        FETCH_TASK: "v1/approval/myapprovals",
        APPROVE_TASK: "v1/approval/approve/",
        REJECT_TASK: "v1/approval/reject/",
        APPROVAL_HISTORY: "v1/approval/history/"
    },
    REGISTER_CUSTOMER: {
        GET_INFO: "v1/accountholder/userinfo/msisdn/",
        VALIDATE_NUMBER: "v1/customer/validate/number",
        FETCH_DETAILS: "v1/customer/fetch/number",
        REGISTER_CUSTOMER: "v1/accountholder/registerCustomer",
        RESEND_OTP: "v1/generate/otp",
        GET_PROFILES: '/v1/profiles'
    },
    M2M : {
        PAYMERCHANT: 'v1/pay/merchant'
    },
    DUE_AMOUNT_COLLECTION:{
        DUE_AMOUNT_COLLECTION:  "v1/dueamountcollection"
    },
    SALARY_APROVAL:{
        LIST_URL: '/v1/corporateApproval/get?',
        VIEW_URL: "/v1/bulkPayment/offline/viewIncompleteData?fileId=",
        APPROVAL_URL:'/v1/corporateApproval/approveRequest/',
        REFRESH_STATUS_URL:"/v1/bulkPayment/offline/refreshFileStatus/",
    },
    LIST_VOTING_EVENTS:{
        LIST_URL:'v1/accountholder?entityType=8'
    },

    WATER_BILL_URLS:{
        FETCH_CONSUMER_URL:'/v1/waterbill/fetch-consumer?consumerNo=',
        FETCH_BILL_URL:'/v1/waterbill/fetch-bill?waterConnectionId=',
        WATER_BILL_URL:'/v1/waterbill/payment'

    },
    LAND_BILL_URLS:{
        FETCH_PLOT_URL_tTin:'/v1/land-tax/fetch-plotByTtin?tTin=',
        FETCH_PLOT_URL_cid:'/v1/land-tax/fetch-plotByCid?cid=',
        FETCH_BILL_URL:'/v1/land-tax/fetch-bill?calendarYearId=',
        LAND_BILL_URL:'/v1/land-tax/payTax'

    }

}
