import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loadable from 'react-loadable';

import { ErrorPage, Loading as Loader } from '../../errorPage/ErrorPage';
import { ajaxUtil, setHeaderUtil, saveCurrentStateUtil, setNotification, setModalPopupUtil, setLoadingUtil, isComplexTab } from '../Utils';
import { MESSAGE_UTILS } from '../../../util/Messages';
import { CONSTANTS, GLOBAL_CONSTANTS } from '../../../util/Constants';
import { PRIVILIAGES as MENU_PRIVILIAGES } from '../../../util/Privilages';
import { ProductInput as ProductInputComponent } from '@6d-ui/ui-helpers';
// import TestPage from "../../_test/";
import { RoutePropsContexts } from '../../util/CustomContexts';

const ProductInput = props => <ProductInputComponent {...props} productWithMsisdn={GLOBAL_CONSTANTS.PRODUCT_WITH_SIM_MSISDN} />

const Loading = (props) => {
  if (props.isLoading) {
    if (props.timedOut) {
      return <ErrorPage errorCode={404} />;
    } else {
      return <Loader />;
    }
  } else if (props.error) {
    return <ErrorPage errorCode={500} />;
  } else {
    return <ErrorPage errorCode={404} />;
  }
}

function createLoadable(loader) {
  return Loadable({
    loader,
    loading: Loading
  });
}

const AsyncHome = createLoadable(() => import('../../dashboard/DashBoard').catch(e => console.error(e)));

const AsyncSysConfig = Loadable({
  loader: () => import('system-configs'),
  loading: Loading
});
const AsyncDoctTypeView = Loadable({
  loader: () => import('document-type'),
  loading: Loading
});
const AsyncChangePassword = Loadable({
  loader: () => import('../../changePswd/ChangePswd'),
  loading: Loading
});
const AsyncChangePin = Loadable({
  loader: () => import('../../changePin/changePin'),
  loading: Loading
});

const AsyncCompanyType = Loadable({
  loader: () => import('company-type'),
  loading: Loading
});
const AsyncChannelView = Loadable({
  loader: () => import('../../channel-management/View').catch(err => console.error(err)),
  loading: Loading
});
const AsyncChannelRegister = Loadable({
  loader: () => import('../../channel-management/Create'),
  loading: Loading
});

const AsyncChannelInfo = Loadable({
  loader: () => import('../../channel-management/ChannelDashBoard'),
  loading: Loading
})

const AsyncUserProfile = Loadable({
  loader: () => import('../UserProfile'),
  loading: Loading
})
//Settings->Service
const AsyncSettingsService = Loadable({
  loader: () => import('../../service-settings/Service'),
  loading: Loading
})
const AsyncServiceClass = Loadable({
  loader: () => import('service-class'),
  loading: Loading
});
const AsyncOrganisation = Loadable({
  loader: () => import('../../channel-partner-hierarchy').then(module => module.Organisation),
  loading: Loading
});
const AsyncChannelPartnerInfo = Loadable({
  loader: () => import('channel-partner-hierarchy').then(module => module.ViewChannelPartner),
  loading: Loading
});
const AyncWelcomeBonus = Loadable({
  loader: () => import('../../servicebonus/ServiceBonus'),
  loading: Loading
})
const AsyncChannelPartnerCreate = Loadable({
  loader: () => import('../../channel-partner-hierarchy').then(module => module.CreateChannelPartners),
  loading: Loading
});
const AsyncChannelPartnerView = Loadable({
  loader: () => import('../../channel-partner-hierarchy').catch(err => console.error(err)),
  loading: Loading
});
const AsyncUserCreate = Loadable({
  loader: () => import('../../user-management/CreateUser'),
  loading: Loading
});
const AsyncUserView = Loadable({
  loader: () => import('../../user-management/View'),
  loading: Loading
});
const AsyncRoleCreate = Loadable({
  loader: () => import('../../role-management').then(module => module.CreateRole),
  loading: Loading
});
const AsyncRoleInfo = Loadable({
  loader: () => import('../../role-management').then(module => module.RoleDetails),
  loading: Loading
});
const AsyncRoleEdit = Loadable({
  loader: () => import('../../role-management').then(module => module.EditRole),
  loading: Loading
});
const AsyncRoleView = Loadable({
  loader: () => import('../../role-management').then(module => module.View),
  loading: Loading
});
const AsyncServiceFee = Loadable({
  loader: () => import('../../serviceFee/ServiceFee'),
  loading: Loading
});
const AsyncTopUp = Loadable({
  loader: () => import('../../services/topUp/TopUp'),
  loading: Loading
});
const AsyncManageTax = Loadable({
  loader: () => import('../../manageTax/ManageTax'),
  loading: Loading
});
const AsyncCustomerCareReport = Loadable({
  loader: () => import('../../reports/customerCareReport/CustomerCareReport'),
  loading: Loading
});
const AsyncProfiles = Loadable({
  loader: () => import('../../profile/Profile'),
  loading: Loading
});
const AsyncFloatTransfer = Loadable({
  // loader: () => import('../../services/floatTransfer/ViewFloatTransfer'),
  loader: () => import('../../services/floatTransfer/FloatTransfer'),
  loading: Loading
})
// const AsyncWithdraw = Loadable({
//   loader: () => import('../../services/withDrawal/ViewWithDrawal'),
//   loading: Loading
// });
const AsyncWithdraw = Loadable({
  loader: () => import('../../services/withDraw/WithDrawal'),
  loading: Loading
});
const AsyncAgentTransfer = Loadable({
  //loader: () => import('../../services/agentTransfer/ViewAgentTransfer'),
  loader: () => import('../../services/agentTransfer/LevelTransfer'),
  loading: Loading
})
const AsyncMessageTemplate = Loadable({
  loader: () => import('../../message-template/MessageTemplates'),
  loading: Loading
});
const AsyncBroadcast = Loadable({
  loader: () => import('../../broadcast/Broadcast'),
  loading: Loading
})
const AsyncWalletPrivileges = Loadable({
  loader: () => import('../../walletPrivileges/WalletPrivileges'),
  loading: Loading
})
const AsyncSubscription = Loadable({
  loader: () => import('../../subscription/Subscription'),
  loading: Loading
});
const AsyncCommissionBalance = Loadable({
  loader: () => import('../../commissionBalance/CommissionBalance'),
  loading: Loading
});
const AsyncreferralBonus = Loadable({
  loader: () => import('../../referral-Bonus/ReferralBonus'),
  loading: Loading
});
const AsyncSystemWallets = Loadable({
  loader: () => import('../../system-wallets/SystemWallets'),
  loading: Loading
});
const AsyncCashIn = Loadable({
  loader: () => import('../../cashIn/CashIn'),
  loading: Loading
});
const AsyncCashOut = Loadable({
  loader: () => import('../../cashOut/CashOut'),
  loading: Loading
});
const AsyncBillPayment = Loadable({
  loader: () => import('../../services/billPayment/BillPayment'),
  loading: Loading
});
const AsyncLeaseLine = Loadable({
  loader: () => import('../../services/leaseLine/LeaseLine'),
  loading: Loading
});
const AsyncCashInBank = Loadable({
  loader: () => import('../../services/cashInBank/cashInBank'),
  loading: Loading
});
const AsyncRedeemCoupon = Loadable({
  loader: () => import('../../services/redeemCoupon/redeemCoupon'),
  loading: Loading
});
const AsyncPromotion = Loadable({
  loader: () => import('../../services/promotion/promotion'),
  loading: Loading
});
const AsyncOfflineBilling = Loadable({
  loader: () => import('../../offlineBilling/OfflineBilling'),
  loading: Loading
});
const AsyncDistributeSalary = Loadable({
  loader: () => import('../../distributeSalary/DistributeSalary'),
  loading: Loading
});
const AsyncTransferCommission = Loadable({
  loader: () => import('../../transferCommission/TransferCommission'),
  loading: Loading
});
const AsyncTransactionReport = Loadable({
  loader: () => import('../../reports/transactionReport/TransactionReport'),
  loading: Loading
});
const AsyncAgentReport = Loadable({
  loader: () => import('../../reports/agentReport/AgentReport'),
  loading: Loading
});
const AsyncUserAccountReport = Loadable({
  loader: () => import('../../reports/userAccountReport/UserAccountReport'),
  loading: Loading
});
const AsyncUserBalanceReport = Loadable({
  loader: () => import('../../reports/userbalanceReport/UserBalanceReport'),
  loading: Loading
});
const AsyncBalanceSettlementReport = Loadable({
  loader: () => import('../../reports/balanceSettlementReport/BalanceSettlementReport'),
  loading: Loading
});
const AsyncMerchantReport = Loadable({
  loader: () => import('../../reports/merchantReport/MerchantReport'),
  loading: Loading
})
const AsyncSettlementReport = Loadable({
  loader: () => import('../../reports/settlementReport/SettlementReport'),
  loading: Loading
});
const AsyncCommissionManagement = Loadable({
  loader: () => import('../../commission-management/Commission'),
  loading: Loading
});
const AsyncCashBack = Loadable({
  loader: () => import('../../cashBack/CashBack'),
  loading: Loading
});
const AsyncStatusCode = Loadable({
  loader: () => import('../../statuscode/View'),
  loading: Loading
});
const AsyncUpdateKYC = Loadable({
  loader: () => import('../../verifyKYC/VerifyKYC'),
  loading: Loading
});
const AsyncCashoutToBank = Loadable({
  loader: () => import('../../services/cashoutToBank/CashoutToBank'),
  loading: Loading
});
const AsyncWelcome = Loadable({
  loader: () => import('../../welcome/Welcome'),
  loading: Loading
})
const AsyncRequestMoney = Loadable({
  loader: () => import('../../services/requestMoney/Money'),
  loading: Loading
});
const AsyncElectricityBillPay = Loadable({
  loader: () => import('../../services/electricityBillPay/ElectricityBillPayment'),
  loading: Loading
});

const AsyncTaxBillPay = Loadable({
  loader: () => import('../../services/taxPay/TaxPayment'),
  loading: Loading
});

const AsyncLifeInsurancePay = Loadable({
  loader: () => import('../../services/lifeInsurancePay/LifeInsurance'),
  loading: Loading
});
const AsyncLifeAnnuityPay = Loadable({
  loader: () => import('../../services/lifeAnnuityPay/LifeAnnuity'),
  loading: Loading
});
const AsynLoanRepay = Loadable({
  loader: () => import('../../services/loanRepay/LoanRepay'),
  loading: Loading
});

const AsyncTask = Loadable({
  loader: () => import('../../tasks/MyTask'),
  loading: Loading
})
const AsyncRegisterCustomer = Loadable({
  loader: () => import('../../registerCustomer/RegisterCustomer'),
  loading: Loading
});
const AsyncOfflineBillerReport = Loadable({
  loader: () => import('../../reports/offlineBillerReport/OfflineBillerReport'),
  loading: Loading
})
const AsyncPayMerchant = Loadable({
  loader: () => import('../../services/payMerchant/PayMerchant'),
  loading: Loading
})
const AsyncDeregisteredUserReport = Loadable({
  loader: () => import('../../reports/deregisteredUserReport/DeregisteredUserReport'),
  loading: Loading
})

const AsysncBillerTxnWiseTxnReport = Loadable({
  loader: () => import('../../reports/txnWiseTxnReport/TxnWiseTxnReport'),
  loading: Loading
})
const AsysncCashoutHistoryReport = Loadable({
  loader: () => import('../../reports/cashOutHistoryReport/CashOutHistoryReport'),
  loading: Loading
})
const AsysncDateWiseTransactionReport = Loadable({
  loader: () => import('../../reports/dateWiseTransactionReport/DateWiseTransactionReport'),
  loading: Loading
})
const AsysncCorporateSalaryPaymentReport = Loadable({
  loader: () => import('../../reports/corporateSalaryPaymentReport/CortporateSalaryPaymentReport'),
  loading: Loading
})
const AsysncCorporateTransactionReport = Loadable({
  loader: () => import('../../reports/corporateTransactionReport/CorporateTransactionReport'),
  loading: Loading
})
const AsysncDueAmountReport = Loadable({
  loader: () => import('../../reports/dueAmountReport/DueAmountReport'),
  loading: Loading
})
const AsyncDueAmountCollection = Loadable({
  loader: () => import('../../services/dueAmountCollection/DueAmountCollection'),
  loading: Loading
});

const AsyncElectricityReport = Loadable({
  loader: () => import('../../reports/electricityReport/ElectricityReport'),
  loading: Loading
})
const AsyncTaxReport = Loadable({
  loader: () => import('../../reports/taxReport/TaxReport'),
  loading: Loading
})
const AsyncRICBLoanRepaymentSuccessReport = Loadable({
  loader: () => import('../../reports/ricblLoanRepaymentSuccessReport/RICBLLoanRepaymentSuccessReport'),
  loading: Loading
})
const AsyncRICBLoanRepaymentFailureReport = Loadable({
  loader: () => import('../../reports/ricblLoanRepaymentFailureReport/RICBLLoanRepaymentFailureReport'),
  loading: Loading
})
const AsyncRICBLoanInsuranceSuccessReport = Loadable({
  loader: () => import('../../reports/ricblLoanInsuranceSuccessReport/RICBLLoanInsuranceSuccessReport'),
  loading: Loading
})
const AsyncRICBLoanInsuranceFailureReport = Loadable({
  loader: () => import('../../reports/ricblLoanInsuranceFailureReport/RICBLLoanInsuranceFailureReport'),
  loading: Loading
})
const AsyncRICBLoanAnnuitySuccessReport = Loadable({
  loader: () => import('../../reports/ricblLoanAnnuitySuccessReport/RICBLLoanAnnitySuccessReport'),
  loading: Loading
})
const AsyncRICBLoanAnnuityFailureReport = Loadable({
  loader: () => import('../../reports/ricblLoanAnnuityFailureReport/RICBLLoanAnnityFailureReport'),
  loading: Loading
})
const AsyncMerchantTopupReport = Loadable({
  loader: () => import('../../reports/merchantTopup/MerchantTopupReport'),
  loading: Loading
})
const AsyncSalaryApprove = Loadable({
  loader: () => import('../../services/salaryAproval/SalaryApproval'),
  loading: Loading
});
const AsyncVotingPaymentReport = Loadable({
  loader: () => import('../../reports/votingPaymentReport/VotingPaymentReport'),
  loading: Loading
});

const AsyncWaterBillPayment = Loadable({
  loader: () => import('../../services/waterBillPayment/WaterBillPayment'),
  loading: Loading
});
const AsyncLandBillPayment = Loadable({
  loader: () => import('../../services/landBillPayment/LandBillPayment'),
  loading: Loading
});

const AsyncUserSpecificReport = Loadable({
  loader: () => import('../../reports/userSpecificReport/UserSpecificReport'),
  loading: Loading
});
const AsyncRegionWiseReport = Loadable({
  loader: () => import('../../reports/regionWiseReport/RegionWiseReport'),
  loading: Loading
});
const AsyncWaterBillPaymentReport = Loadable({
  loader: () => import('../../reports/waterBillPaymentReport/WaterBillPaymentReport'),
  loading: Loading
});
const AsyncLandTaxPaymentReport = Loadable({
  loader: () => import('../../reports/landTaxPaymentReport/LandTaxPaymentReport'),
  loading: Loading
});
const AsyncBTRechargeReport = Loadable({
  loader: () => import('../../reports/btRechargeReport/BTRechargeReport'),
  loading: Loading
});

export const Routes = ({ userid, privilages, previousState, userChannelType, userEntityType, designationId, loggedInUser, exportResponseHandler }) => {
  const properties = {
    'userId': userid,
    'privilages': privilages,
    'ajaxUtil': ajaxUtil,
    'setHeader': setHeaderUtil,
    'saveCurrentState': saveCurrentStateUtil,
    'setNotification': setNotification,
    'setModalPopup': setModalPopupUtil,
    'loadingFunction': setLoadingUtil,
    'messagesUtil': MESSAGE_UTILS,
    'designationId': designationId,
    'globalConstants': GLOBAL_CONSTANTS,
    loggedInUser,
    exportResponseHandler
  }

  return (
    <RoutePropsContexts.Provider value={properties}>
      <div style={{ height: '100%' }}>
        <Switch>
          <Route exact path="/home" render={() => <AsyncHome
            {...properties}
            urlConstants={CONSTANTS.DASHBOARD}
            menuPrivilages={MENU_PRIVILIAGES.DASHBOARD} />} />



          {/** Add your routes here **/}
          <Route exact path="/SystemConfigs" render={() => <AsyncSysConfig
            {...properties}
            urlConstants={CONSTANTS.SYSTEM_CONFIG}
            menuPrivilages={MENU_PRIVILIAGES.SYSTEM_CONFIG}
          />} />
          <Route exact path="/DocumentType" render={() => <AsyncDoctTypeView
            {...properties}
            urlConstants={CONSTANTS.DOCTYPE}
            menuPrivilages={MENU_PRIVILIAGES.DOCTYPE}
          />} />
          <Route exact path="/changePswd" render={() => <AsyncChangePassword
            {...properties}
            urlConstants={CONSTANTS.CHANGE_PSWD}
            redirect="home"
          />} />
          <Route exact path="/channels" render={() => <AsyncChannelView
            {...properties}
            URLS={CONSTANTS.ACCOUNT_HOLDER}
            menuPrivilages={MENU_PRIVILIAGES.ACCOUNT_HOLDERS}
            listProfile={CONSTANTS.SERVICE_FEE.GET_PROFILES}
            list_systemWallets_url={CONSTANTS.SYSTEM_WALLETS.GET_WALLETS_URL}
            GET_OTP_URL={CONSTANTS.REGISTER_CUSTOMER.RESEND_OTP}
            VIEW_TRANSACTION_REPORT={CONSTANTS.REPORTS.VIEW_TRANSACTION_REPORT}
          />} />
          <Route exact path="/channels/register" render={() => <AsyncChannelRegister
            {...properties}
            URLS={CONSTANTS.ACCOUNT_HOLDER}
            listProfile={CONSTANTS.SERVICE_FEE.GET_PROFILES}
          />} />
          <Route exact path="/channels/view" render={(props) => <AsyncChannelInfo
            {...properties}
            {...props}
            URLS={CONSTANTS.ACCOUNT_HOLDER}
            listProfile={CONSTANTS.SERVICE_FEE.GET_PROFILES}
            menuPrivilages={MENU_PRIVILIAGES.ACCOUNT_HOLDERS}
            GET_OTP_URL={CONSTANTS.REGISTER_CUSTOMER.RESEND_OTP}
          />} />

          <Route exact path="/userProfile" render={(props) => <AsyncUserProfile
            {...properties}
            {...props}
            url_User={CONSTANTS.USER_MGMNT}
            url_AccountHolder={CONSTANTS.ACCOUNT_HOLDER}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            menuPrivilages_AccountHolder={MENU_PRIVILIAGES.ACCOUNT_HOLDERS}
            previousState={previousState && previousState.obj.user}
            previousStateKey="user"
            redirect="home"
          />} />

          <Route exact path="/broadcast" render={(props) => <AsyncBroadcast
            {...properties}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            menuPrivilages={MENU_PRIVILIAGES.BROADCAST}
            URLS={CONSTANTS.BROADCAST}
            url_ErrorCode={CONSTANTS.ERROR_CODE_URL}
          />} />

          <Route exact path="/walletPrivileges" render={(props) => <AsyncWalletPrivileges
            {...properties}
            URLS={CONSTANTS.WALLET_PRIVILEGES}
            menuPrivilages={MENU_PRIVILIAGES.WALLET_PRIVILLEGES}

          />} />
          <Route exact path="/subscription" render={() => <AsyncSubscription
            {...properties}
            url_AccountHolder={{ ...CONSTANTS.ACCOUNT_HOLDER }}
            urls={CONSTANTS.SUBSCRIPTION}
            menuPrivilages={MENU_PRIVILIAGES.SUBSCRIPTION}

          />} />
          <Route exact path="/monthlyCommission" render={() => <AsyncCommissionBalance
            {...properties}
            url_AccountHolder={{ ...CONSTANTS.ACCOUNT_HOLDER }}
            urls={CONSTANTS.COMMISSIONBALANCE}
            menuPrivilages={MENU_PRIVILIAGES.MONTHLYCOMMISSION}

          />} />

          <Route exact path="/referralbonus" render={() => <AsyncreferralBonus
            {...properties}

            url_AccountHolder={{ ...CONSTANTS.ACCOUNT_HOLDER }}
            urls={CONSTANTS.REFERRALBONUS}
            menuPrivilages={MENU_PRIVILIAGES.REFERRALBONUS}

          />} />
          <Route exact path="/changePswd" render={() => <AsyncChangePassword
            {...properties}
            urlConstants={CONSTANTS.CHANGE_PSWD}
            redirect="home"
          />} />

          <Route exact path="/changePin" render={() => <AsyncChangePin
            {...properties}
            urlConstants={CONSTANTS.CHANGE_PIN}
            redirect="home"
          />} />

          <Route exact path="/CompanyType" render={() => <AsyncCompanyType
            {...properties}
            urlConstants={CONSTANTS.COMPANY_TYPE}
            menuPrivilages={MENU_PRIVILIAGES.COMPANY_TYPE}
          />
          }
          />
          <Route exact path="/ServiceClass" render={() => <AsyncServiceClass
            {...properties}
            urlConstants={CONSTANTS.SERVICE_CLASS}
            menuPrivilages={MENU_PRIVILIAGES.SERVICE_CLASS}
          />
          }
          />
          <Route exact path="/organisation" render={() => <AsyncOrganisation
            {...properties}
            const_SalesHierarchy={CONSTANTS.SALES_HIERARCHY}
            menuPrivilages={MENU_PRIVILIAGES.SALES_HIERARCHY}
            url_GetRoles={GLOBAL_CONSTANTS.GET_ROLES_URL}
          />} />
          <Route exact path="/welcomebonus" render={(props) => <AyncWelcomeBonus
            {...properties}
            url_WelcomeBonus={CONSTANTS.WELCOME_BONUS}
            menuPrivilages={MENU_PRIVILIAGES.BONUS}
            URLS={CONSTANTS.ACCOUNT_HOLDER}
            listProfile={CONSTANTS.SERVICE_FEE.GET_PROFILES}
          />} />
          <Route exact path="/channelPartners/view/:id" render={(props) => <AsyncChannelPartnerInfo
            {...props}
            {...properties}
            url_ChannelPartners={CONSTANTS.CHANNEL_PARTNERS}
            url_CompanyType={CONSTANTS.COMPANY_TYPE}
            url_ServiceClass={CONSTANTS.SERVICE_CLASS}
            url_SalesHierarchy={CONSTANTS.SALES_HIERARCHY}
            url_SalesTerritory={CONSTANTS.SALES_TERRITORY}
            url_DocType={CONSTANTS.DOCTYPE}
            const_dateFormat={GLOBAL_CONSTANTS.DATE_FORMAT}
          />} />
          <Route exact path="/channelPartners/create/:id/:type/:parentId" render={(props) => <AsyncChannelPartnerCreate
            {...props}
            {...properties}
            url_ChannelPartners={CONSTANTS.CHANNEL_PARTNERS}
            url_CompanyType={CONSTANTS.COMPANY_TYPE}
            url_DocType={CONSTANTS.DOCTYPE}
            url_GetProducts={GLOBAL_CONSTANTS.GET_PRODUCTS_URL}
            url_ServiceClass={CONSTANTS.SERVICE_CLASS}
            url_SalesHierarchy={CONSTANTS.SALES_HIERARCHY}
            url_SalesTerritory={CONSTANTS.SALES_TERRITORY}
            productInputComponent={ProductInput}
          />} />
          <Route exact path="/channelPartners" render={() => <AsyncChannelPartnerView
            {...properties}
            menuPrivilages={MENU_PRIVILIAGES.CHANNEL_PARTNERS}
            url_ChannelPartners={CONSTANTS.CHANNEL_PARTNERS}
            url_User={CONSTANTS.USER_MGMNT}
            url_SalesHierarchy_ListUrl={CONSTANTS.SALES_HIERARCHY.LIST_URL}
            url_SalesHierarchy_OpNodeType={CONSTANTS.SALES_HIERARCHY.OP_NODE_TYPE}
            url_SalesHierarchy_BuNodeType={CONSTANTS.SALES_HIERARCHY.BU_NODE_TYPE}
          />} />
          <Route exact path="/User/create" render={(props) => <AsyncUserCreate
            {...props}
            {...properties}
            userChannelType={userChannelType}
            userEntityType={userEntityType}
            url_User={CONSTANTS.USER_MGMNT}
            url_AccountHolder={CONSTANTS.ACCOUNT_HOLDER}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
          />
          }
          />
          <Route exact path="/User" render={() => <AsyncUserView
            {...properties}
            url_User={CONSTANTS.USER_MGMNT}
            url_AccountHolder={CONSTANTS.ACCOUNT_HOLDER}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            menuPrivilages={MENU_PRIVILIAGES.USER_MGMNT}
            previousState={previousState && previousState.obj.user}
            previousStateKey="user"
          />} />
          <Route exact path="/Roles/create" render={() => <AsyncRoleCreate
            {...properties}
            url_Roles={CONSTANTS.ROLES}

          />} />
          <Route exact path="/Roles/view/:id" render={(props) => <AsyncRoleInfo
            {...props}
            {...properties}
          />} />
          <Route exact path="/Roles/edit/:id" render={(props) => <AsyncRoleEdit
            {...props}
            {...properties}
            url_Roles={CONSTANTS.ROLES}
          />} />
          <Route exact path="/Roles" render={() => <AsyncRoleView
            {...properties}
            url_Roles={CONSTANTS.ROLES}
            menuPrivilages={MENU_PRIVILIAGES.ROLES}
            previousState={previousState && previousState.obj.roles}
            previousStateKey="roles"
          />} />

          <Route exact path="/serviceFee" render={() => <AsyncServiceFee
            {...properties}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            menuPrivilages={MENU_PRIVILIAGES.SERVICE_FEE}
          />} />

          <Route exact path="/Messages" render={() => <AsyncMessageTemplate
            {...properties}
            menuPrivilages={MENU_PRIVILIAGES.MESSAGE_TEMPLATE}
            urlConstants={CONSTANTS.MESSAGE_TEMPLATES}
            urlErrorCodes={CONSTANTS.ERROR_CODE_URL}
            urlAccountHolder={CONSTANTS.ACCOUNT_HOLDER}
          />} />

          <Route exact path="/profile" render={(props) => <AsyncProfiles
            {...props}
            {...properties}
            urlConstants={CONSTANTS.PROFILE}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            menuPrivilages={MENU_PRIVILIAGES.PROFILE}
          />} />

          <Route exact path="/floatTransfer" render={() => <AsyncFloatTransfer
            {...properties}
            URLS={CONSTANTS.FLOAT_TRANSFER}
            menuPrivilages={MENU_PRIVILIAGES.floatTransfer}
          />
          } />
          {/* <Route exact path="/withdraw" render={() => <AsyncWithdraw
            {...properties}
            URLS={CONSTANTS.FLOAT_TRANSFER}
            menuPrivilages={MENU_PRIVILIAGES.floatwithdraw}
          /> */}
          <Route exact path="/withdraw" render={() => <AsyncWithdraw
            {...properties}
            urlConstants={CONSTANTS.WITHDRAWAL}

            menuPrivilages={MENU_PRIVILIAGES.floatwithdraw}
          />
          } />
          <Route exact path="/levelTransfer" render={() => <AsyncAgentTransfer
            {...properties}
            URLS={CONSTANTS.AGENT_TRANSFER}
            menuPrivilages={MENU_PRIVILIAGES.levelTransfer}
          />
          } />

          <Route exact path="/systemWallets" render={() => <AsyncSystemWallets
            {...properties}
            url_systemWallets={CONSTANTS.SYSTEM_WALLETS}
            menuPrivilages={MENU_PRIVILIAGES.SYSTEM_WALLETS}
            stateKey="systemWallets"
          />} />
          <Route exact path="/cashIn" render={() => <AsyncCashIn
            {...properties}
            urlConstants={CONSTANTS.CASHIN}
            menuPrivilages={MENU_PRIVILIAGES.cashin}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
          />} />
          <Route exact path="/cashOut" render={() => <AsyncCashOut
            {...properties}
            menuPrivilages={MENU_PRIVILIAGES.cashout}
            urlConstants={CONSTANTS.CASHOUT}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            getWalletAmountBalance={CONSTANTS.REQUEST_MONEY.GET_BALANCE}
          />} />

          <Route exact path="/billPayment" render={() => <AsyncBillPayment
            {...properties}
            url={CONSTANTS.BILLPAYMENT}
            urlConstants={CONSTANTS.CASHIN}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            getUserWalletDetails_URL={CONSTANTS.ACCOUNT_HOLDER.BALANCE}
            menuPrivilages={MENU_PRIVILIAGES.billPayment}
            applyCoupon_url={CONSTANTS.TOPUP.APPLY_COUPON}
          />} />

          <Route exact path="/leaseline" render={() => <AsyncLeaseLine
            {...properties}
            url={CONSTANTS.LEASELINE}
            urlConstants={CONSTANTS.CASHIN}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            getUserWalletDetails_URL={CONSTANTS.ACCOUNT_HOLDER.BALANCE}
            menuPrivilages={MENU_PRIVILIAGES.leaseLine}
            applyCoupon_url={CONSTANTS.TOPUP.APPLY_COUPON}
          />} />

          <Route exact path="/cashInBank" render={(props) => <AsyncCashInBank
            {...props}
            {...properties}
            urlConstants={CONSTANTS.CASHOUT_IN_BANK}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
          />} />

          <Route exact path="/redeemCoupon" render={() => <AsyncRedeemCoupon
            {...properties}
            urlConstants={CONSTANTS.REDEEM_COUPON}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
          />} />

          <Route exact path="/promotion" render={() => <AsyncPromotion
            {...properties}
            urlConstants={CONSTANTS.REDEEM_COUPON}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
          />} />

          <Route exact path="/offlineBilling" render={() => <AsyncOfflineBilling
            {...properties}
            urlConstants={CONSTANTS.OFFLINEBILLING}
            menuPrivilages={MENU_PRIVILIAGES.offlineBilling}
          />} />
          <Route exact path="/distributeSalary" render={() => <AsyncDistributeSalary
            {...properties}
            urlConstants={CONSTANTS.DISTRIBUTE_SALARY}
            urlScheduler={CONSTANTS.LEASELINE}
            menuPrivilages={MENU_PRIVILIAGES.DISTRIBUTE_SALARY}
          />} />

          <Route exact path="/transferCommission" render={() => <AsyncTransferCommission
            {...properties}
            urlConstants={CONSTANTS.TRANSFER_COMMISSION}
            getCommissionBalance={CONSTANTS.DASHBOARD.GET_BALANCE}
            menuPrivilages={MENU_PRIVILIAGES.TRANSFER_COMMISSION}
          />} />

          <Route exact path="/transactionReport" render={() => <AsyncTransactionReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

          <Route exact path="/agentReport" render={() => <AsyncAgentReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getStates_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            getBussinessTypes_url={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

          <Route exact path="/merchantReport" render={() => <AsyncMerchantReport
            {...properties}
            listUrl={CONSTANTS.REPORTS.MERCHANT_REPORT}
            urlConstants={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            getLocation_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            get_all_business_types={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}

          />} />

          <Route exact path="/userAccountReport" render={() => <AsyncUserAccountReport
            {...properties}
            urlConstants={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
          />} />

          <Route exact path="/userBalanceReport" render={() => <AsyncUserBalanceReport
            {...properties}
            urlConstants={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/balanceSettlementReport" render={() => <AsyncBalanceSettlementReport
            {...properties}
            urlConstants={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/settlementReport" render={() => <AsyncSettlementReport
            {...properties}
            listUrl={CONSTANTS.REPORTS}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getWalletTypeUrl={CONSTANTS.REPORTS.GET_WALLET_TYPES}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />


          <Route exact path="/manageTax" render={() => <AsyncManageTax
            {...properties}
            url_ManageTax={CONSTANTS.MANAGE_TAX}
            menuPrivilages={MENU_PRIVILIAGES.TAX_MANAGEMENT}
          />} />


          <Route exact path="/customercareReport" render={() => <AsyncCustomerCareReport
            {...properties}

            URLS={CONSTANTS.REPORTS}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

          <Route exact path="/commission" render={() => <AsyncCommissionManagement
            {...properties}
            commission_urls={CONSTANTS.COMMISSION}
            url_ServiceFee={{ ...CONSTANTS.SERVICE_FEE }}
            GET_SERVICES={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            GET_PROFILES={CONSTANTS.SERVICE_FEE.GET_PROFILES}
            menuPrivilages={MENU_PRIVILIAGES.COMMISSION}
          />} />

          <Route exact path="/cashBack" render={() => <AsyncCashBack
            {...properties}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            menuPrivilages={MENU_PRIVILIAGES.SERVICE_FEE}
          />} />

          <Route exact path="/service" render={() => <AsyncSettingsService
            {...properties}
            menuPrivilages={MENU_PRIVILIAGES.SETTINGS_SERVICE}
            url_Service={CONSTANTS.SERVICE}
          />} />
          <Route exact path="/statusCode" render={() => <AsyncStatusCode
            {...properties}
            urlConstants={CONSTANTS.ERROR_CODE_URL}
            menuPrivilages={MENU_PRIVILIAGES.STATUS_CODE}
          />} />

          <Route exact path="/channels/verifyKYC" render={() => <AsyncUpdateKYC
            {...properties}
            URLS={CONSTANTS.ACCOUNT_HOLDER}
          />} />

          <Route exact path="/cashoutToBank" render={(props) => <AsyncCashoutToBank
            {...properties}
            {...props}
            urlConstants={CONSTANTS.CASHOUT_TO_BANK}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            menuPrivilages={MENU_PRIVILIAGES.cashoutToBank}
            getWalletAmountBalance={CONSTANTS.REQUEST_MONEY.GET_BALANCE}
          />} />
          <Route exact path="/topup" render={() => <AsyncTopUp
            urlConstants={CONSTANTS.TOPUP}
            getUserWalletDetails_URL={CONSTANTS.ACCOUNT_HOLDER.BALANCE}
            menuPrivilages={MENU_PRIVILIAGES.topUp}
          />} />

          <Route exact path="/welcome" render={() => <AsyncWelcome
            url_AccountHolder={CONSTANTS.ACCOUNT_HOLDER}
            URLS={CONSTANTS.WELCOME}
            contextMenu_URL={CONSTANTS.MESSAGE_TEMPLATES.GET_MSG_TAGS}
            menuPrivilages={MENU_PRIVILIAGES.WELCOME}
          />
          } />
          <Route exact path="/requestMoney" render={() => <AsyncRequestMoney
            {...properties}
            url={CONSTANTS.REQUEST_MONEY}

            menuPrivilages={MENU_PRIVILIAGES.REQUEST_MONEY}
          />} />
          <Route exact path="/electricityPay" render={() => <AsyncElectricityBillPay
            {...properties}
            urlConstants={CONSTANTS.ELECTRICITY_PAY}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}

            menuPrivilages={MENU_PRIVILIAGES.ELECTRICITY_PAY}
          />} />
          <Route exact path="/taxPay" render={() => <AsyncTaxBillPay
            {...properties}
            urlConstants={CONSTANTS.TAX_PAY}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}

            menuPrivilages={MENU_PRIVILIAGES.TAX_PAY}
          />} />
           <Route exact path="/lifeInsurance" render={() => <AsyncLifeInsurancePay
            {...properties}
            urlConstants={CONSTANTS.LIFE_INSURANCE}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}

            menuPrivilages={MENU_PRIVILIAGES.LIFE_INSURANCE}
          />} />
          <Route exact path="/lifeAnnuity" render={() => <AsyncLifeAnnuityPay
            {...properties}
            urlConstants={CONSTANTS.LIFE_ANNUITY}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}

            menuPrivilages={MENU_PRIVILIAGES.LIFE_ANNUITY}
          />} />
          <Route exact path="/loanRepay" render={() => <AsynLoanRepay
            {...properties}
            urlConstants={CONSTANTS.LOAN_REPAYMENT}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}

            menuPrivilages={MENU_PRIVILIAGES.LOAN_REPAYMENT}
          />} />
          
          <Route exact path="/tasks" render={() => <AsyncTask
            {...properties}
            url={CONSTANTS.TASK}
            menuPrivilages={MENU_PRIVILIAGES.task}
          />} />

          <Route exact path="/registerCustomer" render={() => <AsyncRegisterCustomer
            {...properties}
            URL={CONSTANTS.REGISTER_CUSTOMER}
            url_AccountHolder={CONSTANTS.ACCOUNT_HOLDER}
            menuPrivilages={MENU_PRIVILIAGES.REGISTER_CUSTOMER}
          />
          } />
          <Route exact path="/offlineBillerReport" render={() => <AsyncOfflineBillerReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.OFFLINE_BILLER_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.OFFLINE_BILLER_REPORT_DOWNLOAD_XLS}
          />} />
          <Route exact path="/payMerchant" render={() => <AsyncPayMerchant
            URLS={CONSTANTS.M2M}
          />} />
          <Route exact path="/deregisteredUserReport" render={() => <AsyncDeregisteredUserReport
            listUrl={CONSTANTS.REPORTS.DEREGISTERED_USER_REPORT}
            viewRequestLetter_url={CONSTANTS.REPORTS.REQUEST_LETTER_VIEW}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.DEREGISTERED_USER_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.DEREGISTERED_USER_REPORT_DOWNLOAD_XLS}
            getServices_url={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
          />} />
          <Route exact path="/billerTxnWiseTxnReport" render={() => <AsysncBillerTxnWiseTxnReport
            listUrl={CONSTANTS.REPORTS.VIEW_BILLER_TXN_WISE_TXN_REPORT}
            report_download_csv={CONSTANTS.REPORTS.BILLER_TXN_WISE_TXN_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.BILLER_TXN_WISE_TXN_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/cashoutHistoryReport" render={() => <AsysncCashoutHistoryReport
            listUrl={CONSTANTS.REPORTS.VIEW_BILLER_CASHOUT_HISTORY_REPORT}
            getBank_url={CONSTANTS.ACCOUNT_HOLDER.GET_BANK}
            report_download_csv={CONSTANTS.REPORTS.BILLER_CASHOUT_HISTORY_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.BILLER_CASHOUT_HISTORY_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

          <Route exact path="/dateWiseTransactionReport" render={() => <AsysncDateWiseTransactionReport
            listUrl={CONSTANTS.REPORTS.VIEW_BILLER_DATEWISE_TRANSACTION_REPORT}
            report_download_csv={CONSTANTS.REPORTS.BILLER_DATEWISE_TRANSACTION_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.BILLER_DATEWISE_TRANSACTION_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

          <Route exact path="/corporateSalaryPaymentReport" render={() => <AsysncCorporateSalaryPaymentReport
            listUrl={CONSTANTS.REPORTS.VIEW_CORPORATE_SALARY_PAYMENT_REPORT}
            report_download_csv={CONSTANTS.REPORTS.CORPORATE_SALARY_PAYMENT_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.CORPORATE_SALARY_PAYMENT_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/dueAmountReport" render={() => <AsysncDueAmountReport
            {...properties}
            listUrl={CONSTANTS.REPORTS.VIEW_DUEAMOUNT_REPORT}
            report_download_csv={CONSTANTS.REPORTS.DUEAMOUNT_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.DUEAMOUNT_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/corporateTransactionReport" render={() => <AsysncCorporateTransactionReport
            listUrl={CONSTANTS.REPORTS.VIEW_CORPORATE_TRANSACTION_REPORT}
            report_download_csv={CONSTANTS.REPORTS.CORPORATE_TRANSACTION_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.CORPORATE_TRANSACTION_REPORT_DOWNLOAD_XLS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

        <Route exact path="/electricityReport" render={() => <AsyncElectricityReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            report_download_csv={CONSTANTS.REPORTS.ELECTRICITY_REPORT_CSV}
            report_download_xls={CONSTANTS.REPORTS.ELECTRICITY_REPORT_XLS}
          />} />

          <Route exact path="/taxReport" render={() => <AsyncTaxReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            report_download_csv={CONSTANTS.REPORTS.TAX_REPORT_CSV}
            report_download_xls={CONSTANTS.REPORTS.TAX_REPORT_XLS}
          />} />
          <Route exact path="/dueAmountCollection" render={() => <AsyncDueAmountCollection
            {...properties}
            url={CONSTANTS.DUE_AMOUNT_COLLECTION}
            menuPrivilages={MENU_PRIVILIAGES.DUE_AMOUNT_COLLECTION}
          />} />
          
          <Route exact path="/RICBLoanRepaymentSuccessReport" render={() => <AsyncRICBLoanRepaymentSuccessReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_REPAYMENT_SUCCESS_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_REPAYMENT_SUCCESS_REPORT_DOWNLOAD_XLS}
          />} />
           <Route exact path="/RICBLoanRepaymentFailureReport" render={() => <AsyncRICBLoanRepaymentFailureReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_REPAYMENT_FAILURE_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_REPAYMENT_FAILURE_REPORT_DOWNLOAD_XLS}
          />} />
          <Route exact path="/RICBLoanInsuranceSuccessReport" render={() => <AsyncRICBLoanInsuranceSuccessReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_SUCCESS_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_SUCCESS_REPORT_DOWNLOAD_XLS}
          />} />

          <Route exact path="/RICBLoanInsuranceFailureReport" render={() => <AsyncRICBLoanInsuranceFailureReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_XLS}
          />} />

          <Route exact path="/RICBLoanAnnuitySuccessReport" render={() => <AsyncRICBLoanAnnuitySuccessReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_ANNUITY_SUCCESS_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_ANNUITY_SUCCESS_REPORT_DOWNLOAD_XLS}
          />} />
           <Route exact path="/RICBLoanAnnuityFailureReport" render={() => <AsyncRICBLoanAnnuityFailureReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            report_download_csv={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_CSV}
            report_download_xls={CONSTANTS.REPORTS.RICB_LOAN_INSURANCE_FAILURE_REPORT_DOWNLOAD_XLS}
          />} />

          <Route exact path="/merchantTopup" render={() => <AsyncMerchantTopupReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            url_ServiceFee={CONSTANTS.SERVICE_FEE}
            report_download_csv={CONSTANTS.REPORTS.MERCHANT_TOPUP_REPORT_CSV}
            report_download_xls={CONSTANTS.REPORTS.MERCHANT_TOPUP_REPORT_XLS}
          />} />
          <Route exact path="/salaryApprove" render={() => <AsyncSalaryApprove
            {...properties}
            urlConstants={CONSTANTS.SALARY_APROVAL}
            menuPrivilages={MENU_PRIVILIAGES.salaryApproval}

          />
          } />
          <Route exact path="/votingPaymentReport" render={() => <AsyncVotingPaymentReport
            {...properties}
            urlConstants={CONSTANTS.REPORTS}
            getEventUrl={CONSTANTS.LIST_VOTING_EVENTS.LIST_URL}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            
          />} />
          <Route exact path="/waterBillPayment" render={() => <AsyncWaterBillPayment
          illPayment
            {...properties}
            urlConstants={CONSTANTS.WATER_BILL_URLS}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            menuPrivilages={MENU_PRIVILIAGES.WATER_BILL}
            
          />} />
          <Route exact path="/landBillPayment" render={() => <AsyncLandBillPayment
          illPayment
            {...properties}
            urlConstants={CONSTANTS.LAND_BILL_URLS}
            getCharges_url={CONSTANTS.TOPUP.GET_CHARGES}
            menuPrivilages={MENU_PRIVILIAGES.LAND_BILL}
            
          />} />
          <Route exact path="/userSpecificReport" render={() => <AsyncUserSpecificReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getStates_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            getBussinessTypes_url={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
          <Route exact path="/regionWiseReport" render={() => <AsyncRegionWiseReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getEntity_url={CONSTANTS.ACCOUNT_HOLDER.GET_ENTITIES}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getStates_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            getBussinessTypes_url={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
            getProfiles_url={CONSTANTS.PROFILE.PROFILE_URL}
          />} />
          <Route exact path="/waterBillPaymentReport" render={() => <AsyncWaterBillPaymentReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getStates_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            getBussinessTypes_url={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />

        <Route exact path="/landTaxPaymentReport" render={() => <AsyncLandTaxPaymentReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            getLevels_url={CONSTANTS.ACCOUNT_HOLDER.GET_LEVEL}
            getServiceUrl={CONSTANTS.SERVICE_FEE.GET_SERVICES}
            getStates_url={CONSTANTS.ACCOUNT_HOLDER.GET_STATES}
            getBussinessTypes_url={CONSTANTS.ACCOUNT_HOLDER.GET_ALL_BUSSINESS_TYPES}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          />} />
        <Route exact path="/BTRechargeReport" render={() => <AsyncBTRechargeReport
            {...properties}
            URLS={CONSTANTS.REPORTS}
            menuPrivilages={MENU_PRIVILIAGES.REPORTS}
          
          />} />
          {/* <Route path="/_test" render={() => <TestPage />} /> */}

          <Route exact path="/" render={() => <Redirect to="/home" />} />
          <Route path="/" render={() => <ErrorPage errorCode={404} />} />

        </Switch>
      </div>
    </RoutePropsContexts.Provider>
  )
}
