import React, { useState, useEffect } from "react";

import { ResponsiveContainerContext } from "./CustomContexts";

const ResponsiveContainer = props => {
  const height = useWindowSize().windowHeight;
  const width = useWindowSize().windowWidth;

  const { offset = 136, isFromDashboard, division = 2.1 } = props;
  const heightToUse = {
    height: height ? height - offset : 500
  };
  const widthToUse = {
    width: width && isFromDashboard ? width - width / division : "auto"
    // width: width && isFromDashboard ? width - width / 2.1 : "auto"
  };

  const className = `overlay_position scrollbar${
    props.className ? " " + props.className : ""
  }`;

  return (
    <div className={className} style={{ ...heightToUse, ...props.style }}>
      <ResponsiveContainerContext.Provider
        value={{ height: heightToUse, width: widthToUse }}
      >
        {props.children}
      </ResponsiveContainerContext.Provider>
    </div>
  );
};

const useWindowSize = () => {
  const isClient = typeof window === "object";

  const getHeight = () => (isClient ? window.innerHeight : undefined);

  const getWidth = () => (isClient ? window.innerWidth : undefined);

  const [windowHeight, setWindowHeight] = useState(getHeight);
  const [windowWidth, setWindowWidth] = useState(getWidth);

  useEffect(() => {
    if (!isClient) return false;

    const handleResize = () => {
      setWindowHeight(getHeight);
      setWindowWidth(getWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return { windowHeight, windowWidth };
};

export default ResponsiveContainer;
