import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Input,
  Button, Tooltip
} from 'reactstrap';
import { CONSTANTS } from '../../util/Constants';
import { setHeaderUtil, saveCurrentStateUtil, setNotification, setModalPopupUtil, setLoadingUtil, isComplexTab } from '../home/Utils';
import { connect } from 'react-redux';

import { setLoading, logOut } from '../../actions';
//import { useHistory } from 'react-router-dom';
import { store } from '../../index';
import { encrypt } from "../../util/Util";
import { CHANGE_PSWD_POLICY_URL,CHANGE_PSWD_URL, CHANNEL, AUTH_KEY,ENCYPT_KEY,ENCYPT_KEY_AES } from '../../util/Constants';
import { AjaxUtil } from '@6d-ui/ajax';
import { Redirect, Link } from 'react-router-dom';
const ajaxUtil = new AjaxUtil({
  'responseCode': {
    'success': 200,
    'unAuth': 401,
    'resultSuccess': '0'
  },
  'messages': {
    'success': "Success Message",
    'failure': "Failure Message"
  },
  'authKey': AUTH_KEY,
  'channel': CHANNEL,
  'encrptKey': ENCYPT_KEY_AES,
  'encryptAES':true
});

function ChangePassword(props) {

  const [newPassword, setNewPassword] = useState('');
  const [password, setPassword] = useState('');
  const [passShow, setPassShow] = useState('');
  const [confPassShow, setConfPassShow] = useState('');
  const [newPassShow, setNewPassShow] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isShow, setIsShow] = useState({ password: false, newPass: false, confPass: false });
  const [pin, setPin] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  //const history = useHistory();

  //console.log("props----->",props);
  const height = useWindowHeight();
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const handlePassworChange = (name, val) => {
    if (name === "password") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = password;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < password.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setPassShow(str);
        setPassword(value);
      } else {
        setPassShow('');
        setPassword('');
      }
    }
    if (name === "confPass") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = confirmPassword;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < confirmPassword.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setConfPassShow(str);
        setConfirmPassword(value);
      } else {
        setConfPassShow('');
        setConfirmPassword('');
      }
    }
    if (name === "newPass") {
      if (val && val.length > 0) {
        let str = '';
        const elem = document.getElementById('sampleChar');
        let value = newPassword;
        for (var i = 0; i < val.length; i++) {
          // str += '*';
          // str += String.fromCharCode(parseInt('U+000B7', 16));
          str += elem.textContent;
          if (val.length < newPassword.length) {
            value = value.substring(0, val.length)
          } else if (i == val.length - 1) {
            value += val[i];
          }

        }
        setNewPassShow(str);
        setNewPassword(value);
      } else {
        setNewPassShow('');
        setNewPassword('');
      }
    }
  }
  function setShow(name) {
    const isShowTmp = { ...isShow };
    if (name === "password") {
      isShowTmp.password = !isShowTmp.password;
    }
    if (name === "newPass") {
      isShowTmp.newPass = !isShowTmp.newPass;
    }
    if (name === "confPass") {
      isShowTmp.confPass = !isShowTmp.confPass;
    }
    setIsShow(isShowTmp);
  }

  const handleLoginSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      setError("Please enter your current password !");
      return false;
    }
    if (!newPassword) {
      setError("Please enter your new password !");
      return false;
    }
    if (!confirmPassword) {
      setError("Please confirm your new password !");
      return false;
    }
    if (confirmPassword != newPassword) {
      setError("New Password and Confirm password must be same !");
      return false;
    }
    const createCheck = (response, hasError) => {
      if (hasError) {
        setError(response.message);
        return
      }
      else {
        console.log("--redirect")
        props.login.userDetails.forcepin === true?setPin(true):store.dispatch(logOut("Password succesfully changed"));
        //setPin(true);          // return <Redirect to="forcePin" />
        // return  <Link to="/forcePin"></Link>
      }
      // else
      // {
      //   return true;

      // }
      //const respMsg = response.responseMsg || "Please Try Again";
      //props.setLoginMessage("");
    }
    let userId;
    if (props.login)
      userId = props.login.userDetails && props.login.userDetails.userId
    const req = {
      //userId: userId,
      password: encrypt(password),
      // newPassword: encrypt(this.state.newPwd),
      newPassword: encrypt(newPassword),
      // confirmPassword: encrypt(this.state.confirmPwd)
      confirmPassword: encrypt(confirmPassword)
    }
    const intialReq={
      newPassword:encrypt(newPassword),
      msisdn:props.login.userDetails.msisdn
    }
    setError("");
    
    ajaxUtil.sendRequest(CHANGE_PSWD_POLICY_URL, intialReq ,(response,hasError)=>{
      if(hasError)
      {
        if(response)
        {
          setError(response.message);
        }
      }
      else{
        setError("");
        ajaxUtil.sendRequest(CHANGE_PSWD_URL, req,createCheck,null, { isShowSuccess: true, isShowFailure: false })
        
      }
    },null, { isShowSuccess: true, isShowFailure: true })


  };
  const formContainerRef = useRef(null);
  let formHeight = 414;
  // useEffect(() => {
  //   formHeight = formContainerRef.current.offsetHeight;
  // }, []);
  const getErrorMsg = () => {
    if (error || props.message) {
      return (
        <div className="errorMsg_login" style={{ color: '#ed1c24' }}>
          {error || props.message}
        </div>
      );
    }
  }
  return (
    props.login && !props.login.isLoggedIn ? <Redirect to="/login" /> :
      !pin ?
        <Container fluid>
          <Row>
            <Col xs={6} lg={8} style={{ height: `${height}px`, background: 'linear-gradient(40deg, #F9CF01, #ED7818, #007AC3)' }} className="bg-secondary login-container"></Col>
            <Col xs={6} lg={4} className="bg-white">
              <div>
                <div>
                  <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px`, marginLeft: '100px' }}>
                    <img src={`${process.env.PUBLIC_URL}/images/logo/MM_logo.png`} alt="6d Technologies" style={{ height: '100px', maxWidth: '180px' }} />
                  </div>

                  {/* login form starts here */}
                  <div id="sampleChar" style={{ display: 'none' }}>&#183;</div>
                  <div className="login-form-container bg-white" ref={formContainerRef} style={{ marginLeft: '-232.5px' }}>
                    <div className="login-form-header">Change Password</div>
                    <form onSubmit={handleLoginSubmit} className="login-form">
                      {getErrorMsg()}
                      <div className="pt-1" style={{ color: '#', cursor: 'pointer' }}>
                        <InputGroup>
                          <Input onChange={(event) => handlePassworChange("password", event.target.value)}
                            value={isShow.password ? password : passShow} onPaste={(e) => e.preventDefault()}
                          />
                          <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={() => setShow("password")}></i></InputGroupAddon>
                          <span className="floating-label">Old password</span>
                        </InputGroup>
                      </div>
                      <div className="pt-1" style={{ color: '#495057', cursor: 'pointer' }}>

                        <InputGroup>
                          <Input onChange={(event) => handlePassworChange("newPass", event.target.value)}
                            value={isShow.newPass ? newPassword : newPassShow} onPaste={(e) => e.preventDefault()}
                            id="TooltipPassword"></Input>
                          <Tooltip placement="left" isOpen={tooltipOpen} target="TooltipPassword" toggle={toggle} style={{backgroundColor:"black",border:"10px",textAlign:"left"}} multiline={true}>
                            <div style={{color:"white"}}>
                           <b>Password should contain</b>
                            <ul>
                              <li>Minimum length of 4 and max 15</li>
                              <li>Can be alpha-numeric characters or numeric only</li>
                              <li>Password will be checked against English in order to reject easy to guess choices</li>
                              <li>Detect and block simple passwords (e.g. 123456, abcdef etc.)</li>
                              <li>Case Sensitive</li>
                              <li>Check that the password is not the user ID</li>
                              <li>Check that the password is not blank</li>
                            </ul>
                            
                            </div>
                          </Tooltip>

                          <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={() => setShow("newPass")}></i></InputGroupAddon>
                          <span className="floating-label">New password</span>

                        </InputGroup>


                      </div>
                      <div className="pt-1" style={{ color: '#495057', cursor: 'pointer' }}>
                        <InputGroup>
                          <Input onChange={(event) => handlePassworChange("confPass", event.target.value)}
                            value={isShow.confPass ? confirmPassword : confPassShow} onPaste={(e) => e.preventDefault()}
                          />
                          <InputGroupAddon addonType="append" style={{ lineHeight: '38px' }}><i className="fa fa-eye" style={{ width: '21px' }} onClick={() => setShow("confPass")}></i></InputGroupAddon>
                          <span className="floating-label">Confirm new password</span>
                        </InputGroup>
                      </div>

                      {
                        props.isLoading
                          ? <Button className="w-100 login-button primary-background" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }} disabled>
                            <i className="fa fa-spinner fa-spin"></i><span> authenticating...</span>
                          </Button>
                          : <Button className="w-100 login-button c-pointer primary-background" style={{ backgroundColor: '#ED7818', borderColor: '#ED7818' }}>Submit</Button>}

                      <div className="d-flex justify-content-end">
                        <Button onClick={() => store.dispatch(logOut())} style={{ backgroundColor: "#f1f2f300", borderColor: "#ffffff", color: "black" }}><i class="fa fa-arrow-left" aria-hidden="true"></i>    Back to Login</Button>
                      </div>

                    </form>

                  </div>

                  <div className="logo-container position-relative" style={{ height: `${(height - formHeight) / 2}px` }}>
                    <span>Ooredoo ©2019. All Rights Reserved</span>
                  </div>

                </div>
              </div>
            </Col>
          </Row>

        </Container> : <Redirect to="forcePin" />
  )

}


function useWindowHeight() {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => setHeight(window.innerHeight);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return height;
}

function mapStateToProps({ login }) {
  return { login };
}

const mapDispatchToProps = dispatch => {
  return {
    setLoading: (...obj) => dispatch(setLoading(...obj)),
    setLoginMessage: (msg) => dispatch(logOut())
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
