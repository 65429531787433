import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setCredentials } from '@6d-ui/ajax';
import { validateLogin } from '../actions';

const LoginValidator = (props) => {

    useEffect(() => {
        if (!props.login || !props.login.isLoggedIn) {
            props.validateLogin();
        }
    }, []);

    const { login, isFromLogin = false } = props;

    if (login && login.isLoggedIn) {
        setCredentials(login.userDetails.token, login.userDetails.userId,login.userDetails.msisdn);
    }

    if (!login || !login.hasOwnProperty("isLoggedIn")) {
        return (
            <div className="index_loaderMain">
                <div className="index_loaderSub">
                    <div className="sk-folding-cube">
                        <div className="sk-cube1 sk-cube"></div>
                        <div className="sk-cube2 sk-cube"></div>
                        <div className="sk-cube4 sk-cube"></div>
                        <div className="sk-cube3 sk-cube"></div>
                    </div>
                </div>
            </div>
        );
    }
    if (login && login.userDetails && login.userDetails.forcepswd) {
        return <Redirect to="forceChangePassword" />
        //return <Redirect to="forcePin" />
    }
    else if(login && login.userDetails && login.userDetails.forcepin)
    {
        return <Redirect to="forcePin" />
    }
    else if (login && login.isLoggedIn) {
       return <Redirect to="home" />;
    }
    else{
        isFromLogin && setCredentials('', '');
        return props.children;
    }

}

const mapStateToProps = state => {
    return {
        login: state.login,
        isLoading: state.loader.isLoading
    };
}

export default connect(mapStateToProps, { validateLogin })(LoginValidator);