import React from 'react';

import { ErrorPage } from '../../errorPage/ErrorPage';
import { PRIVILAGE_ROUTE_MAP, DEFAULT, DEFAULT_VIEW } from '../../../util/PrivilageRouteMap';

export default({location,privilages,children})=>
{
    if(location && location.pathname && isPrivilageAssigned(location.pathname,privilages)) return children;
    else return <ErrorPage errorCode={404} />

}
const isPrivilageAssigned=(path,privilages)=>
{
const priv=getAssignedPrivilages(path);
return privilages.includes(priv) || priv==DEFAULT_VIEW

}
const getAssignedPrivilages=path=>
{
    if(!path)
    return undefined;
    const url = path.replace(/[/]/g, " ").trim().split(" ");
    if(url.length === 1 && url[0] === "") return DEFAULT_VIEW;
    let data = { ...PRIVILAGE_ROUTE_MAP };
    url && url.map(val => {
        if(val && val.trim() && isNaN(val.trim())) data = getData(val.trim(), data);
    });
    if(typeof data === 'object' || typeof data === 'function') return data[DEFAULT];
    return data ? data : {};
}
const getData = (key, map) => {
    if(map[key]) return map[key];
    return {};
}