import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setCredentials } from '@6d-ui/ajax';
import LoginContainer from './LoginContainer';
import LoginValidator from '../../util/LoginValidator';


class Login extends Component {

    render() {
        return <LoginValidator>
            <div>
                <LoginContainer message={this.props.login.respMsg} successcheck = { this.props.type }/>
            </div>
        </LoginValidator>;

    }
}

function mapStateToProps(state) {
    return { login: state.login };
}

export default connect(mapStateToProps)(Login);
