import React from 'react';
import { connect } from 'react-redux';

import LoginForm from './LoginForm';
import { logIn, setLoading } from '../../actions';
import LoginPage from './LoginPage';

function LoginContainer(props) {
    const onLoginClick = (userName, password) => {
        props.setLoading({ isLoading: true });
        props.logIn({ username: userName, password });
    }

    // return <LoginForm
    //     isLoading={props.isLoading}
    //     onSubmit={onLoginClick}
    //     message={props.message}
    // />
    // new login page added
    return <LoginPage 
    isLoading={props.isLoading}
    onSubmit={onLoginClick}
    message={props.message}
/>;
}

function mapStateToProps({ loader }, props) {    
    return { 
        isLoading: loader.isLoading,
        type: props.NEW_PASSWORD_SUCCESS
    };
}
export default connect(mapStateToProps, { logIn, setLoading })(LoginContainer);