export const FormElements = {
    mobileNumber: {
        name: "mobileNumber",
        label: "Mobile Number",
        width: "xs",
        minLength: 8,
        maxLength: 10,
        regex: /^[0-9]{8,10}$/,
        ismandatory: true
    },
    date: {
        name: "date",
        label: "Date of Birth",
        width: "xs",
        ismandatory: true
    },
    otp: {
        name: "otp",
        label: "OTP",
        width: "xs",
        minLength: 6,
        maxLength: 6,
        regex: /^[0-9]{6,6}$/,
        ismandatory: true
    },
    password: {
        name: "password",
        label: "New Password",
        width: "xs",
        minLength: 4,
        maxLength: 15,
       // regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        ismandatory: true,
        // messages: {
        //     regex: 'Your password must contain atleast 1 number, uppercase and lowercase respectively'
        // }
    },
    confirmedpassword: {
        name: "confirmedpassword",
        label: "Confirm Password",
        width: "xs",
        minLength: 4,
        maxLength: 15,
       // regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        ismandatory: true,
        // messages: {
        //     regex: 'Your password must contain atleast 1 number, uppercase and lowercase respectively'
        // }
    }
}