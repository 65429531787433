import _ from "lodash";

export const PRIVILIAGES = {

  BONUS: {
    view: 12018,
    create: 12019,
    update: 12020,
    delete: 12021
  },
  SYSTEM_CONFIG: {
    view: 21000,
    create: 21001,
    edit: 21002,
    delete: 21003
  },
  COMPANY_TYPE: {
    view: 23000,
    create: 23001,
    edit: 23002,
    delete: 23003
  },
  DOCTYPE: {
    view: 24000,
    create: 24001,
    edit: 24002,
    delete: 24003
  },
  SERVICE_CLASS: {
    view: 28000,
    create: 28001,
    edit: 28002,
    delete: 28003
  },
  CHANNEL_PARTNERS: {
    view: 6000,
    create: 6001,
    edit: 6002,
    delete: 6003
  },
  SALES_HIERARCHY: {
    view: 4000,
    create: 4001,
    edit: 4002
  },
  USER_MGMNT: {
    view:3000,
    create: 3001,
    edit: 3002,
    delete: 3003
  },
  ROLES: {
    view: 2000,
    create: 2001,
    edit: 2002
  },
  PROFILE: {

    create: 5001,
    edit: 5002
  },
  MESSAGE_TEMPLATE:
  {
    view: 12022,
    create: 12023,
    edit: 12024,
    delete: 12025
  },
  SERVICE_FEE: {
    create: 6001,
    edit: 6002,
    delete: 6003
  },
  ACCOUNT_HOLDERS: {
    create: 1001,
    view: 1024,
    info: 1025,
    resetPin: 1007,
    resetPassword: 1006,
    changeStatus: 1003,
    changePassword: 1004,
    changePin: 1008,
    viewAllChildWallet: 1005,
    updateEmail: 1009,
    updateMSISDN: 1010,
    viewDocs: 1020,
    updateDocs: 1021,
    updateKYC: 9017,
    download: 1001,
    viewMyReport : 13022,
    viewContestant: 1031,
    updateContestant: 1032,
    viewEvent: 1029,
    updateEvent: 1030,
    deRegister_Agent :1028 ,
    viewUserBalance: 1047,
    businessDescriptionApproval:1023
  
  },
  SYSTEM_WALLETS: {
    stockTransafer: 10005,
    view: 10006,
    addStock: 10000,
    custodyWallet: 10001,
    incomeWallet: 10002,
    expenceWallet: 10003,
    viewReport: 10004,
    taxWallet: 10007
  },
  REPORTS: {
    viewTransactionReport: 13000,
    downloadTransactionReport: 13001,
    viewDealerTopupReport: 13002,
    downloadDealerTopupReport: 13003,
    viewMerchantReport: 13004,
    downloadMerchantReport: 13005,
    viewAgentReport: 13006,
    downloadAgentReport: 13007,
    viewNotificationReport: 13008,
    downloadNotificationReport: 13009,
    viewSettlementReport: 13010,
    downloadSettlementReport: 13011,
    viewBalanceSettleReport: 13012,
    downloadBalnceSettleReport: 13013,
    viewUserAccountReport: 13014,
    downloadUserAccountReport: 13015,
    viewUserBalanceReport: 13016,
    downloadUserBalanceReport: 13017,
    viewCustomerCareReport: 13018,
    downloadCustomerCareReport:13019,
    viewOfflineBillerReport:13034,
    downloadOfflineBillerReport: 13035,
    viewDeregisteredUserReport :13036,
    viewBillerReports :13037,
    downloadBillerReports :13038,
    viewRequestLetter: 1020,
    downloadDeregisteredUserReport :13036,
    viewCorporatSalaryPaymentReport:13039,
    downloadCorporatSalaryPaymentReport:13040,
    viewCorporateTransactionReport:13041,
    downloadCorporateTransactionReport:13042,
    viewDueAmountReport:13049,
    downloadDueAmountReport:13050,
    viewElectricityReport:13057,
    downloadElectricityReport:13058,
    viewTaxReport:10010,
    downloadTaxReport:10012,
    viewLoanRepaymentReport: 13043,
    downloadLoanRepaymentReport: 13044,
    viewLifeInsuranceReport: 13045,
    downloadLifeInsuranceReport: 13046,
    viewLifeAnnuityReport: 13047,
    downloadLifeAnnuityReport: 13048,
    viewMerchantTopupReport:13061,
    downloadMerchantTopupReport:13062,
    viewVotingEventPaymentReport: 13073,
    downloadVotingEventPaymentReport:13074,
    viewUserSpecificReport:13093,
    downloadUserSpecificReport:13094,
    viewRegionWiseReport: 13088,
    downloadRegionWiseReport:13089,
    viewWaterBillPaymentReport: 13096,
    downloadWaterBillPaymentReport:13097,
    viewLandTaxPaymentReport: 13098,
    downloadLandTaxPaymentReport:13099,
    viewBTRechargeReport: 13113,
    downloadBTRechargeReport: 13114,

  },
  TAX_MANAGEMENT: {
    create: 8001,
    edit: 8002,
    delete: 8003
  },
  COMMISSION: {
    create: 7001,
    edit: 7002,
    delete: 7003
  },
  cashoutToBank: {
    create: 9003
  },
  levelTransfer: {
    create: 9015,

  },
  floatTransfer: {
    create: 9014,
  },
  cashout: {
    create: 9001
  },
  cashin: {
    create: 9000
  },
  floatwithdraw: {
    create: 9016
  },
  topUp: {
    create: 9007
  },

  billPayment: {
    create: 9009
  },
  
  leaseLine: {
  create: 9018
  },
  offlineBilling:
  {
    view: 9019,
    upload: 9020,
    pay: 9012
  },
  MONTHLYCOMMISSION: {
    view: 12026,
    create: 12027,
    edit: 12028,
    delete: 12029
  },
  cashInBank: {
    create: 9002
  },
  redeemCoupon: {
    create: 9006
  },
  promotion:{
    create:9022
  },

  STATUS_CODE:
  {
    view: 12000,
    create: 12001,
    edit: 12002,
   // delete: 12003

  },
  BROADCAST:
  {
    view: 12004,
    create: 12005,
  },
  SETTINGS_SERVICE:
  {
    view: 12006,
    edit: 12007,
  },
  SUBSCRIPTION:
  {
    view: 12010,
    create: 12011,
    edit: 12012,
    delete: 12013,
  },
  REFERRALBONUS:
  {
    view: 12014,
    create: 12015,
    edit: 12016,
    delete: 12017,

  },
  WALLET_PRIVILLEGES:
  {
    view: 12008,
    create: 12009,
  },
  DASHBOARD: {
    viewBalanceCard: 11002,
    viewMerchantMonthly: 11000,
    viewMerchantYearly: 11001,
    viewMerchantTrend: 11010,
    viewAgentYearly:11008,
    viewAgentMonthly: 11009,
    userCountSummary: 11004,
    adminWalletSummary: 11003,
    viewRewardPointMeter : 11011,
    viewCampaignRunning : 11012,
    viewAgentYearly:11008,
    viewBillerMonthly: 11013,
    viewBillerYearly: 11014,
    viewBillerTrend: 11015,
    topTenBillers: 11016,
    ViewBillerCashoutAmount:11017,
    viewCorporateMonthly :11020,
    viewCorporateYearly : 11021,
    viewCorporateTrend : 11022,
    viewCorporateCashin : 11023,
    viewCorporateWeekly : 11024,
    
  },
  WELCOME: {
    view: 12030,
    create: 12031,
    edit: 12032,
    delete: 12033
  },
  REQUEST_MONEY :{
    view:9021
  } ,
  
	REGISTER_CUSTOMER :{
    create:1026
  } ,
  task:{
    view:15000,
    approve:15001,
    reject:15002
  },
 DISTRIBUTE_SALARY:
  {
    view: 9027,
    delete:9063,
    upload:9026,
    pay:9040
  },
  TRANSFER_COMMISSION:
  {
    view: 9028, 
  },
  M2M :{
    PAYMERCHANT :9010
  },
  DUE_AMOUNT_COLLECTION:{
    View:9039
  },
  ELECTRICITY_PAY:{
    view:9058
  },
  TAX_PAY:{
    view:9057
  },
  LIFE_INSURANCE:{
    view:9055
  },
  LIFE_ANNUITY:{
    view:9056
  },
  LOAN_REPAYMENT:{
    view:9054
  },
  salaryApproval :
  {
    view:9064,
    approve:9065

  },
  WATER_BILL: {
    view: 9082
  },
  LAND_BILL: {
    view: 9083
  },

}

export const MENU_DETAILS = [
  {
    id: 1,
    label: "Dashboard",
    linkTo: "/home",
    icon: "fa fa-area-chart",
    privilages: []
    
  }, 
  
{
  id:18 ,
  label: "Tasks",
  icon: "fa fa-tasks",
  linkTo: "/tasks",
  privilages: _.values(PRIVILIAGES.task)
},
  // {
  //   id: 2,
  //   label: "User Management",
  //   icon: "fa fa-user",
  //   submenus: [
  //     {
  //       id: 21,
  //       label: "User",
  //       linkTo: "/User",
  //       icon: "fa fa-users",
  //       privilages:[PRIVILIAGES.USER_MGMNT.view]
  //     },
  //     {
  //       id: 22,
  //       label: "Roles",
  //       linkTo: "/Roles",
  //       icon: "fa fa-users",
  //       privilages: _.values(PRIVILIAGES.ROLES)
  //     },
  //   ]
  // },
  // {
  //   id: 3,
  //   label: "Hierarchy",
  //   icon: "fa fa-cog",
  //   linkTo: "/organisation",
  //   privilages: _.values(PRIVILIAGES.SALES_HIERARCHY)
  // },
  // {
  //   id: 4,
  //   label: "Charges",
  //   icon: "fa fa-money",
  //   submenus: [
  //     {
  //       id: 41,
  //       label: "Service Fee",
  //       linkTo: "/serviceFee",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.SERVICE_FEE)

  //     },
  //     {
  //       id: 42,
  //       label: "Commission",
  //       linkTo: "/Commission",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.COMMISSION)

  //     },
  //     // {
  //     //   id: 43,
  //     //   label: "ManageTax",
  //     //   linkTo: "/taxManagement",
  //     //   icon: "fa fa-money",
  //     //   privilages: _.values(PRIVILIAGES.SERVICE_FEE)

  //     // },
  //     // {
  //     //   id: 44,
  //     //   label: "Cash Back",
  //     //   linkTo: "/cashBack",
  //     //   icon: "fa fa-money",
  //     //   privilages: _.values(PRIVILIAGES.SERVICE_FEE)

  //     // },
  //     {
  //       id: 45,
  //       label: "Tax",
  //       linkTo: "/manageTax",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.TAX_MANAGEMENT)

  //     },
  //     {
  //       id: 46,
  //       label: "Subscription",
  //       linkTo: "/subscription",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.SUBSCRIPTION)
  //     },
  //     {
  //       id: 47,
  //       label: "Referral bonus",
  //       linkTo: "/referralbonus",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.REFERRALBONUS)
  //     },

  //     {
  //       id: 49,
  //       label: "Welcome Bonus",
  //       linkTo: "/welcomebonus",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.BONUS)

  //     },
  //     {
  //       id: 50,
  //       label: "Monthly Commission",
  //       linkTo: "/monthlyCommission",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.MONTHLYCOMMISSION)

  //     },
  //   ]
  // },
  {
    id: 5,
    label: "Account Holder",
    icon: "fa fa-building",
    submenus: [
      {
        id: 51,
        label: "View",
        linkTo: "/channels",
        icon: "fa fa-user",
        privilages: [PRIVILIAGES.ACCOUNT_HOLDERS.view]

      }, 
      {
        id: 52,
        label: "Register",
        linkTo: "/channels/register",
        icon: "fa fa-user",
        privilages: [PRIVILIAGES.ACCOUNT_HOLDERS.create]
      },
      {
        id: 53,
        label: "Verify KYC",
        linkTo: "/channels/verifyKYC",
        icon: "fa fa-user",
        privilages: [PRIVILIAGES.ACCOUNT_HOLDERS.updateKYC]
      },
      {
        id: 54,
        label: "Register Customer",
        linkTo: "/registerCustomer",
        icon: "fa fa-user",
        privilages: [PRIVILIAGES.REGISTER_CUSTOMER.create]
      }

    ]
  },
  {
    id: 6,
    label: "Services",
    icon: "fa fa-tasks",
    submenus: [
      {
        id: 61,
        label: "Float Transfer",
        linkTo: "/floatTransfer",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.floatTransfer)
      },
      // {
      //   id: 62,
      //   label: "Level Transfer",
      //   linkTo: "/levelTransfer",
      //   icon: "fa fa-user",
      //   privilages: _.values(PRIVILIAGES.levelTransfer)
      // },
      {
        id: 63,
        label: "CashIn",
        linkTo: "/cashIn",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.cashin)
      },
      {
        id: 64,
        label: "CashOut",
        linkTo: "/cashOut",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.cashout)
      },
      {
        id: 65,
        label: "Withdrawal",
        linkTo: "/withdraw",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.floatwithdraw)
      },
      {
        id: 37,
        label: "Offline Billing",
        linkTo: "/offlineBilling",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.offlineBilling)
      },
      {
        id: 66,
        label: "Cashout to bank",
        linkTo: "/cashoutToBank",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.cashoutToBank)
      },
      {
        id: 67,
        label: "TopUp",
        linkTo: "/topup",
        icon: "fa fa-arrow-up",
        privilages: _.values(PRIVILIAGES.topUp)
      },
      {
        id: 68,
        label: "Postpaid",
        linkTo: "/billPayment",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.billPayment)
      },
      {
        id: 69,
        label: "Cash in Bank",
        linkTo: "/cashInBank",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.cashInBank)
      },
      {
        id: 70,
        label: "Unregistered Withdrawal",
        linkTo: "/redeemCoupon",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.redeemCoupon)
      },
      {
        id: 71,
        label: "Lease Line",
        linkTo: "/leaseLine",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.leaseLine)
      },
      {
        id: 72,
        label: "Promotion",
        linkTo: "/promotion",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.promotion)
      },
      {
        id: 73,
        label: "Request Money",
        linkTo: "/requestMoney",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.REQUEST_MONEY)
      },
      {
        id: 74,
        label: "Distribute salary",
        linkTo: "/distributeSalary",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.DISTRIBUTE_SALARY)
      },
      {
        id: 76,
        label: "Transfer Commission",
        linkTo: "/transferCommission",
        icon: "fa fa-user",
        privilages: _.values(PRIVILIAGES.TRANSFER_COMMISSION)
      },
      {
        id: 75,
        label: "Merchant To Merchant",
        linkTo: "/payMerchant",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.M2M.PAYMERCHANT]
      },
      {
        id: 77,
        label: "Due Amount Collection",
        linkTo: "/dueAmountCollection",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.DUE_AMOUNT_COLLECTION.View]
      },
      {
        id: 78,
        label: "Electricity Bill Payment",
        linkTo: "/electricityPay",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.ELECTRICITY_PAY.view]
      },
      {
        id: 79,
        label: "Tax Payment",
        linkTo: "/taxPay",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.TAX_PAY.view]
      },
      {
        id: 80,
        label: "Life Insurance",
        linkTo: "/lifeInsurance",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.LIFE_INSURANCE.view]
      },
      {
        id: 81,
        label: "Life Annuity",
        linkTo: "/lifeAnnuity",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.LIFE_ANNUITY.view]
      },
      {
        id: 82,
        label: "Loan Repayment",
        linkTo: "/loanRepay",
        icon: "fa fa-share-square-o",
        privilages:  [PRIVILIAGES.LOAN_REPAYMENT.view]
      },
      {
        id: 83,
        label: "Salary Approval",
        linkTo: "/salaryApprove",
        icon: "fa fa-user",
       privilages: _.values(PRIVILIAGES.salaryApproval)
      },
      {
        id: 84,
        label: "Water Bill Payment",
        linkTo: "/waterBillPayment",
        icon: "fa fa-user",
       privilages: _.values(PRIVILIAGES.WATER_BILL)
      },
      {
        id: 85,
        label: "Land Bill Payment",
        linkTo: "/landBillPayment",
        icon: "fa fa-user",
       privilages: _.values(PRIVILIAGES.LAND_BILL)
      }
    ]
  },
  // {
  //   id: 7,
  //   label: "System Wallets",
  //   icon: "fa fa-credit-card-alt",
  //   linkTo: "/systemWallets",
  //   privilages: _.values(PRIVILIAGES.SYSTEM_WALLETS)
  //   // submenus: [
  //   //   {
  //   //     id: 121,
  //   //     label: "System Wallets",
  //   //     linkTo: "/systemWallets",
  //   //     icon: "fa fa-credit-card-alt"
  //   //   }
  //   // ]
  // },
  // {
  //   id: 8,
  //   label: "Profile",
  //   icon: "fa fa-tasks",
  //   linkTo: "/profile",
  //   privilages: _.values(PRIVILIAGES.PROFILE)
  //   // submenus: [
  //   //   {
  //   //     id: 14,
  //   //     label: "Profile",
  //   //     linkTo: "/profile",
  //   //     icon: "fa fa-user",
  //   //     privilages: _.values(PRIVILIAGES.PROFILE)
  //   //   }
  //   // ]
  // },
  // {
  //   id: 9,
  //   label: "Settings",
  //   icon: "fa fa-credit-card-alt",
  //   submenus: [
  //     {
  //       id: 91,
  //       label: "Message Template",
  //       linkTo: "/Messages",
  //       icon: "fa fa-credit-card-alt",
  //       privilages: _.values(PRIVILIAGES.MESSAGE_TEMPLATE)
  //     },
  //     {
  //       id: 92,
  //       label: "Broadcast",
  //       linkTo: "/broadcast",
  //       icon: "fa fa-credit-card-alt",
  //       privilages: _.values(PRIVILIAGES.BROADCAST)
  //     },
  //     {
  //       id: 93,
  //       label: "Service",
  //       linkTo: "/service",
  //       icon: "fa fa-credit-card-alt",
  //       privilages: _.values(PRIVILIAGES.SETTINGS_SERVICE)
  //     },
  //     // {
  //     //   id: 94,
  //     //   label: "Wallet Privileges",
  //     //   linkTo: "/walletPrivileges",
  //     //   icon: "fa fa-credit-card-alt",
  //     //   privilages: _.values(PRIVILIAGES.WALLET_PRIVILLEGES)
  //     // },
  //     {
  //       id: 95,
  //       label: "Status Code",
  //       linkTo: "/statusCode",
  //       icon: "fa fa-credit-card-alt",
  //       privilages: _.values(PRIVILIAGES.STATUS_CODE)
  //     },
  //     {
  //       id: 96,
  //       label: "Welcome Message",
  //       linkTo: "/welcome",
  //       icon: "fa fa-money",
  //       privilages: _.values(PRIVILIAGES.WELCOME)
  //     },
  //   ]
  // },

  {
    id: 10,
    label: "Reports",
    icon: "fa fa-file",
    submenus: [
      {
        id: 101,
        label: "Transaction Report",
        linkTo: "/transactionReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewTransactionReport]
      },
      // {
      //   id: 102,
      //   label: "Dealer TopUp Report",
      //   linkTo: "/dealerTopUpReport",
      //   icon: "fa fa-file",
      //   //privilages: [PRIVILIAGES.REPORTS.ViewTransactionReport]       
      // },
      {
        id: 103,
        label: "Merchant Report",
        linkTo: "/merchantReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewMerchantReport]
      },
      {
        id: 104,
        label: "Agent Report",
        linkTo: "/agentReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewAgentReport]
      },
      // {
      //   id: 105,
      //   label: "Notification Report",
      //   linkTo: "/notificationReport",
      //   icon: "fa fa-file",
      //   //privilages: [PRIVILIAGES.REPORTS.ViewTransactionReport]       
      // },
      {
        id: 106,
        label: "Settlement Report",
        linkTo: "/settlementReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewSettlementReport]
      },
      // {
      //   id: 107,
      //   label: "Audit Report",
      //   linkTo: "/auditReport",
      //   icon: "fa fa-file",
      //   //privilages: [PRIVILIAGES.REPORTS.ViewTransactionReport]       
      // },
      // {
      //   id: 108,
      //   label: "User Account Report",
      //   linkTo: "/userAccountReport",
      //   icon: "fa fa-file",
      //   privilages: [PRIVILIAGES.REPORTS.viewUserAccountReport]
      // },
      // {
      //   id: 109,
      //   label: "User Balance Report",
      //   linkTo: "/userBalanceReport",
      //   icon: "fa fa-file",
      //   privilages: [PRIVILIAGES.REPORTS.viewUserBalanceReport]
      // },
      // {
      //   id: 110,
      //   label: "Balance Settlement Report",
      //   linkTo: "/balanceSettlementReport",
      //   icon: "fa fa-file",
      //   privilages: [PRIVILIAGES.REPORTS.viewBalanceSettleReport]
      // },
      {
        id: 111,
        label: "Customer Care Report",
        linkTo: "/customercareReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewCustomerCareReport]
      },
      {
        id: 112,
        label: "Offline Biller Report",
        linkTo: "/offlineBillerReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewOfflineBillerReport]
      },
      {
        id: 113,
        label: "Deregistered User Report",
        linkTo: "/deregisteredUserReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewDeregisteredUserReport]
      },
      {
        id: 114,
        label: "Transaction Report",
        linkTo: "/billerTxnWiseTxnReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewBillerReports]
      },
      {
        id: 116,
        label: "Daily Transaction Report",
        linkTo: "/dateWiseTransactionReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewBillerReports]
      },
      {
        id: 115,
        label: "Cashout History Report",
        linkTo: "/cashoutHistoryReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewBillerReports]
      },
      {
        id: 117,
        label: "Salary Payment Report",
        linkTo: "/corporateSalaryPaymentReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewCorporatSalaryPaymentReport]
      },
      {
        id: 118,
        label: "Corporate Transaction Report",
        linkTo: "/corporateTransactionReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewCorporateTransactionReport]
      },
      {
        id: 119,
        label: "Due Amount Colection Report",
        linkTo: "/dueAmountReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewDueAmountReport]
      },
      {
        id: 120,
        label: "Electricity Report",
        linkTo: "/electricityReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewElectricityReport]
      },
      {
        id: 121,
        label: "Tax Report",
        linkTo: "/taxReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewTaxReport]
      },
      {
        id: 125,
        label: "RICB Loan Repayment Success Report ",
        linkTo: "/RICBLoanRepaymentSuccessReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLoanRepaymentReport]
      },
      {
        id: 126,
        label: "RICB Loan Repayment Failure Report ",
        linkTo: "/RICBLoanRepaymentFailureReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLoanRepaymentReport]
      },
      {
        id: 127,
        label: "RICB Life Insurance Success Report ",
        linkTo: "/RICBLoanInsuranceSuccessReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLifeInsuranceReport]
      },
      {
        id: 128,
        label: "RICB Life Insurance Failure Report ",
        linkTo:"/RICBLoanInsuranceFailureReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLifeInsuranceReport]
      },
      {
        id: 129,
        label: "RICB Life Annuity Success Report",
        linkTo:"/RICBLoanAnnuitySuccessReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLifeAnnuityReport]
      },
      {
        id: 130,
        label: "RICB Life Annuity Failure Report",
        linkTo:"/RICBLoanAnnuityFailureReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLifeAnnuityReport]
      },
      {
        id: 131,
        label: "Merchant Topup report",
        linkTo: "/merchantTopup",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewMerchantTopupReport]
      },
      {
        id: 133,
        label: "Voting Payment report",
        linkTo: "/votingPaymentReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewVotingEventPaymentReport]
      },
      {
        id: 134,
        label: "User Specific report",
        linkTo: "/userSpecificReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewUserSpecificReport]
      },
      {
        id: 135,
        label: "Region Wise Report",
        linkTo: "/regionWiseReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewRegionWiseReport]
      },
      {
        id: 136,
        label: " Water Bill Payment report",
        linkTo: "/waterBillPaymentReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewWaterBillPaymentReport]
      },

      {
        id: 137,
        label: " Land Tax Payment report",
        linkTo: "/landTaxPaymentReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewLandTaxPaymentReport]
      },
      {
        id: 138,
        label: "BT Recharge Report",
        linkTo: "/BTRechargeReport",
        icon: "fa fa-file",
        privilages: [PRIVILIAGES.REPORTS.viewBTRechargeReport]
      },
    ]
  },
];