import { PRIVILIAGES } from './Privilages'

export const DEFAULT = "default"
export const DEFAULT_VIEW = 100

export const PRIVILAGE_ROUTE_MAP = {

    home: {
        [DEFAULT]: DEFAULT_VIEW
    },

    //changepassword
    changePswd: {
        [DEFAULT]: DEFAULT_VIEW
    },
    //changepin
    changePin: {
        [DEFAULT]: DEFAULT_VIEW
    },
    // User
    User: {
        [DEFAULT]: PRIVILIAGES.USER_MGMNT.view,
        create: PRIVILIAGES.USER_MGMNT.create
    },
    // Roles
    Roles: {
        [DEFAULT]: PRIVILIAGES.ROLES.view,
        create: PRIVILIAGES.ROLES.create,
        edit: PRIVILIAGES.ROLES.edit
    },
    // Hierarchy,
    organisation: {
        [DEFAULT]: PRIVILIAGES.SALES_HIERARCHY.view
    },
    // Tasks
    tasks: {
        [DEFAULT]: PRIVILIAGES.task.view
    },
    // 
    // cashIn
    cashIn:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    // cashIn
    cashOut:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    // cashIn
    offlineBilling:
    {
        [DEFAULT]: DEFAULT_VIEW,
        upload: PRIVILIAGES.offlineBilling.upload,
        pay: PRIVILIAGES.offlineBilling.pay

    },
    //  distributeSalary
    distributeSalary:
    {
        [DEFAULT]: PRIVILIAGES.DISTRIBUTE_SALARY.view,
        upload: PRIVILIAGES.DISTRIBUTE_SALARY.upload,

    },
    transferCommission:
    {
        [DEFAULT]:PRIVILIAGES.TRANSFER_COMMISSION.view,
    },
    // cashIn
    cashoutToBank:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    topup:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    withdraw:
    {
        [DEFAULT]: PRIVILIAGES.floatwithdraw.create,
    },
    billPayment: {
        [DEFAULT]: DEFAULT_VIEW
    },
    cashInBank:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    redeemCoupon:
    {
        [DEFAULT]: DEFAULT_VIEW
    },
    leaseLine: {
        [DEFAULT]: DEFAULT_VIEW
    },
    // Channels | Account Holder
    channels: {
        [DEFAULT]: PRIVILIAGES.ACCOUNT_HOLDERS.view,
        view: PRIVILIAGES.ACCOUNT_HOLDERS.info,
        register: PRIVILIAGES.ACCOUNT_HOLDERS.create,
        verifyKYC: PRIVILIAGES.ACCOUNT_HOLDERS.updateKYC
    },
    registerCustomer: {
        [DEFAULT]: DEFAULT_VIEW

    },
    // Promotion
    promotion: {
        [DEFAULT]: DEFAULT_VIEW
    },
    // RequestMoney
    requestMoney: {
        [DEFAULT]: PRIVILIAGES.REQUEST_MONEY.view
    },

    // MerchantReport
    merchantReport: {
        [DEFAULT]: PRIVILIAGES.REPORTS.viewMerchantReport
    },
    // AgentReport
    agentReport: {
        [DEFAULT]: PRIVILIAGES.REPORTS.viewAgentReport
    },
    // CustomercareReport
    customercareReport: {
        [DEFAULT]: PRIVILIAGES.REPORTS.viewCustomerCareReport
    },
    settlementReport: {
        [DEFAULT]: PRIVILIAGES.REPORTS.viewSettlementReport
    },
    floatTransfer: {
        [DEFAULT]: PRIVILIAGES.floatTransfer.create
    },
    offlineBillerReport: {
        [DEFAULT]: PRIVILIAGES.REPORTS.viewOfflineBillerReport
    },
    //M2M
    payMerchant: {
        [DEFAULT]: PRIVILIAGES.M2M.PAYMERCHANT
    },
    // Deregistered User Report 
    deregisteredUserReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewDeregisteredUserReport
    },
    // Biller- Transaction Wise Transaction Report
    billerTxnWiseTxnReport :{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewBillerReports
    },
    cashoutHistoryReport :{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewBillerReports
    },
    dateWiseTransactionReport :{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewBillerReports
    },
    transactionReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewTransactionReport
    },
    corporateSalaryPaymentReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewCorporatSalaryPaymentReport
    },
    corporateTransactionReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewCorporateTransactionReport
    },
    dueAmountCollection:{
        [DEFAULT]:PRIVILIAGES.DUE_AMOUNT_COLLECTION.View
    },
    dueAmountReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewDueAmountReport
    },
    electricityReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewElectricityReport
    },
    taxReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewTaxReport
    },
    RICBLoanRepaymentSuccessReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLoanRepaymentReport
    },
    RICBLoanRepaymentFailureReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLoanRepaymentReport
    },
    RICBLoanInsuranceSuccessReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLifeInsuranceReport
    },
    RICBLoanInsuranceFailureReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLifeInsuranceReport
    },
    RICBLoanAnnuitySuccessReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLifeAnnuityReport
    },
    RICBLoanAnnuityFailureReport:{
        [DEFAULT]:PRIVILIAGES.REPORTS.viewLifeAnnuityReport
    },
    electricityPay:{
        [DEFAULT]:PRIVILIAGES.ELECTRICITY_PAY.view
    },
    taxPay:{
        [DEFAULT]:PRIVILIAGES.TAX_PAY.view
    },
    lifeInsurance:{
        [DEFAULT]:PRIVILIAGES.LIFE_INSURANCE.view
    },
    lifeAnnuity:{
        [DEFAULT]:PRIVILIAGES.LIFE_ANNUITY.view
    },
    loanRepay:{
        [DEFAULT]:PRIVILIAGES.LOAN_REPAYMENT.view
    },
    merchantTopup:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewMerchantTopupReport
        //[DEFAULT]: DEFAULT_VIEW
    },
    salaryApprove:{
        [DEFAULT]: PRIVILIAGES.salaryApproval.view
    },
    votingPaymentReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewVotingEventPaymentReport
        //[DEFAULT]: DEFAULT_VIEW
    },
    waterBillPayment:{

        [DEFAULT]: PRIVILIAGES.WATER_BILL.view

    },
    landBillPayment:{

        [DEFAULT]: PRIVILIAGES.LAND_BILL.view
    },
    userSpecificReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewUserSpecificReport
    },
    regionWiseReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewRegionWiseReport
    },
    waterBillPaymentReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewWaterBillPaymentReport
    },
    landTaxPaymentReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewLandTaxPaymentReport
    },
    BTRechargeReport:{
        [DEFAULT]: PRIVILIAGES.REPORTS.viewBTRechargeReport
    }
};