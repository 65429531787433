import React from 'react';
import moment from 'moment';
import CryptoJS from 'crypto-js';
import _ from 'lodash';

import { GLOBAL_CONSTANTS, ENCYPT_KEY, MSISDN_REGEX } from './Constants';

// to get label form selected dropdown values
export function getDropDownValues(values, name) {
    return (values[name] && values[name].label) ? values[name].label : values[name];
}

// to get value form selected dropdown values
export function getValueOfDropDown(values, name) {
    return (values[name] && values[name].value) ? values[name].value : values[name];
}

// formats input date to string 
export function dateToStringFormatter(date) {
    if (date)
        return moment(date).format(GLOBAL_CONSTANTS.DATE_FORMAT);
}

// formats input string to date obj
export function stringToDateFormatter(inputString) {
    if (inputString)
        return moment(inputString, GLOBAL_CONSTANTS.DATE_FORMAT);
}

//returns label,value pair of selected option when label is passed 
export function getValuesForDropDown(options, label) {
    return options.find(option => option.label === label);
}
//returns label,value pair of selected option when value is passed
export function getValuesForDropDownFromValue(options, value) {
    return options.find(option => option.value == value);
}

export function encrypt(data) {
    const keyHex = CryptoJS.enc.Utf8.parse(ENCYPT_KEY);
    const encrypted = CryptoJS.TripleDES.encrypt(data, keyHex, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString()
}

export const getCurrentPrivilages = (privilages, menuPrivilages) => {

    const priv = {
        create: false,
        edit: false,
        delete: false,
        download: false,
        info: false
    };
    let privConst = {};
    if (privilages && menuPrivilages) {
        privConst = {
            create: _.includes(privilages, menuPrivilages.create),
            edit: _.includes(privilages, menuPrivilages.edit),
            delete: _.includes(privilages, menuPrivilages.delete),
            download: _.includes(privilages, menuPrivilages.download),
            info: _.includes(privilages, menuPrivilages.info)
        }
    }

    return Object.assign(priv, privConst);
};

export const arrayMove = (arr, old_index, new_index) => {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};
